import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SharedAsideFactory } from '@shared/src/components/aside/shared.aside.factory';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { RouteCMRController } from '@shared/src/controllers/route/cmr/routecmr.controller';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { HColor } from '@shared/src/datatypes/HColor';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { ActionsStripDto } from '@shared/src/dtos/maintenance/action/ActionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RoutePartCMRDto } from '@shared/src/dtos/route/cmr/RoutePartCMRDto';
import { AlertService } from '@shared/src/public-api';

@Component({
    selector: 'shared-route-part-cmr-maintenance',
    templateUrl: './shared.route.part.cmr.maintenance.component.html',
    styleUrls: ['./shared.route.part.cmr.maintenance.component.scss']
})
export class SharedRoutePartCMRMaintenanceComponent implements OnInit {

    @Input() public readonly: boolean = false;

    constructor(public router: Router, public openRouteCMRController: OpenRouteCMRController,
        @Inject('SharedAsideFactory') private sharedAsideFactory: SharedAsideFactory,
        @Inject('AlertService') private alertService: AlertService,
        @Inject('SecurityController') public securityController: SecurityController) {
        this.securityController.getStoredUserDto().subscribe(data => {
            this.readonly = data.isReadOnly;
        })
    }


    public _routePartCMR: RoutePartCMRDto;
    @Input()
    set routePartCMR(value: RoutePartCMRDto) {
        if (this._routePartCMR === value)
            return;

        this._routePartCMR = value;
        this.loadData();
    }
    get routePartCMR(): RoutePartCMRDto {
        return this._routePartCMR;
    }

    public actions: ActionsStripDto;
    public options: OptionsStripDto;

    @Output() refreshRoute: EventEmitter<boolean> = new EventEmitter();
    @Output() refreshRoutePartCMR: EventEmitter<boolean> = new EventEmitter();
    public loadData() {

        if (this.routePartCMR == null)
            return;

        if (this.routePartCMR.optionsView != null)
            this.options = OptionsStripDto.buildFromOptionsStripDto(this.routePartCMR.optionsView);
        if (this.routePartCMR.actionsView != null)
            this.actions = ActionsStripDto.buildFromActionsStripDto(this.routePartCMR.actionsView);

        this.selectedOptionId = OptionsStripDto.arrange(this.options, this.selectedOptionId, this.routePartCMR.optionViewId);

    }

    onValueChanged() {
        this.refreshRoutePartCMR.next(true);
        this.refreshRoute.next(true);
    }

    onClickAction(action: ActionDto) {
        switch (action.actionId) {
           case "ROUTEPARTCMR.ACTUALIZEDCMRFROMCHILDS":
            if(this.routePartCMR != null)
              return this.openRouteCMRController.actualizedCMRFromChilds(this.routePartCMR.routePartCMRId).subscribe(d => {
                if(d){
                    this.loadData();
                    this.refreshRoutePartCMR.next(true);
                }
              });
            break;
        }
    }

    public selectedOptionId = "";
    onClickOption(option: OptionDto) {
        if (option != null) {
            this.selectedOptionId = option.optionId;
        } else {
            this.selectedOptionId = "";
        }
    }

    getTextColor(data: HColor) {
        if (data != null)
            return HColor.getWhiteBlackFromColor(data.web);

        return HColor.getWhiteBlackFromColor(HColor.defaultColor().web);
    }

    getBackColor(data: HColor) {
        return HColor.getColorOrDefaultColor(data, HColor.defaultColor().web);
    }

    ngOnInit(): void {
    }

    copy(text: string) {
        this.alertService.copyToClipboard(text).subscribe();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}
