<ng-container *ngIf="routePartCMR">
    <div class="main bar">
        <div class="baroptions">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
        </div>
        <div class="baractions">
            <actions *ngIf="!readonly" [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
        </div>
    </div>
    <div class="documentacio incomplerta" [innerHTML]="'ROUTE.PART.INCOMPLETDOCUMENTATION.CAPTION'| translate"
        *ngIf="routePartCMR.isIncompletDocument">
    </div>

    <shared-route-part-cmr-document-component *ngIf="'DIGITALDOCUMENT'==selectedOptionId" class="hideincomplet"
        [value]="routePartCMR">
    </shared-route-part-cmr-document-component>

    <shared-route-part-cmr-edit-additionaldata-component *ngIf="'ADDITIONALDATA'==selectedOptionId"
        [routePartCMRId]="routePartCMR.routePartCMRId">
    </shared-route-part-cmr-edit-additionaldata-component>
    <shared-route-part-cmr-edit-events-component *ngIf="'EVENTS'==selectedOptionId" [routePartCMR]="routePartCMR">
    </shared-route-part-cmr-edit-events-component>
    <shared-route-part-cmr-edit-component *ngIf="'EDIT'==selectedOptionId" (valueChange)="onValueChanged()"
        [routePartCMR]="routePartCMR">
    </shared-route-part-cmr-edit-component>
    <shared-route-part-cmr-qr-component *ngIf="'QR'==selectedOptionId" [routePartCMR]="routePartCMR">
    </shared-route-part-cmr-qr-component>

    <shared-sign-cmr-maintenance *ngIf="'SIGNSENDER'==selectedOptionId" [type]="'sender'"
        [routePartCMR]="routePartCMR"></shared-sign-cmr-maintenance>
    <shared-sign-cmr-maintenance *ngIf="'SIGNCARRIER'==selectedOptionId" [type]="'carrier'"
        [routePartCMR]="routePartCMR"></shared-sign-cmr-maintenance>
    <shared-sign-cmr-maintenance *ngIf="'SIGNCONSIGNEE'==selectedOptionId" [type]="'consignee'"
        [routePartCMR]="routePartCMR"></shared-sign-cmr-maintenance>
    <shared-sign-cmr-maintenance *ngIf="'SIGNDRIVER'==selectedOptionId" [type]="'driver'"
        [routePartCMR]="routePartCMR"></shared-sign-cmr-maintenance>
</ng-container>