import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { FilterStripDto } from '@shared/src/components/core/maintenance/filter/FilterStripDtos';
import { BaseController } from '@shared/src/controllers/base.controller';
import { MaintenanceSearchByTextDto } from '@shared/src/controllers/maintenance/_base/MaintenanceSearchByTextDtos';
import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { StatusTransitionsDto } from '@shared/src/dtos/generalstatus/StatusTransitionsDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { PanelStateRoutesColumnDto } from '@shared/src/dtos/panel/stateroutes/PanelStateRoutesColumnDto';
import { FilterLogDto } from '@shared/src/dtos/route/FilterLogDto';
import { RouteCardDto } from '@shared/src/dtos/route/RouteCardDto';
import { RouteItemMaintenanceCtrlDto } from '@shared/src/dtos/route/RouteItemMaintenanceCtrlDto';
import { RouteListDto } from '@shared/src/dtos/route/RouteListDto';
import { RouteLogDto } from '@shared/src/dtos/route/RouteLogDto';
import { RouteMapInfoDto } from '@shared/src/dtos/route/RouteMapInfoDto';
import { RouteUuidTypeDto } from '@shared/src/dtos/route/RouteUuidTypeDto';
import { RouteReturnInBoundOutBoundDelegatedDto } from '@shared/src/dtos/route/wizard/RouteReturnInBoundOutBoundDelegatedDto';
import { RouteWizardDto } from '@shared/src/dtos/route/wizard/RouteWizardDto';
import { AlertTypes } from '@shared/src/enums/AlertTypes';
import { RouteCardItemListDto } from '@shared/src/dtos/route/RouteCardItemListDto';
import { RouteExworkInfoDto, RouteExworkLogDto } from '@shared/src/public-api';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { RouteGroupDto } from '@shared/src/dtos/route/RouteGroupDto';
import { RouteCardItemDto } from '@shared/src/dtos/route/RouteCardItemDto';
import { RouteCardItemExpandDto } from '@shared/src/dtos/route/RouteCardItemExpandDto';
import { RouteStatusTimeDto } from './dto/RouteStatusTimeDto';
import { SetRoutePartnerTypeForcedDto } from '@shared/src/dtos/route/partner/SetRoutePartnerTypeForcedDto';
import { RoutePartnerTypesForcedBlockedDto } from '@shared/src/dtos/route/partner/RoutePartnerTypesForcedBlockedDto';
import { RoutePartnerExworkDto } from '@shared/src/dtos/route/partner/RoutePartnerExworkDto';
import { RouteGroupChildListDto } from '@shared/src/dtos/route/RouteGroupChildListDto';
import { ActionsAndOptionsDto } from '@shared/src/dtos/maintenance/ActionsAndOptionsDto';
import { RouteDividedInfoDto } from '@shared/src/dtos/route/RouteDividedInfoDto';
import { RouteContainerDataDto } from '@shared/src/dtos/route/RouteContainerDataDto';


@Injectable({
    providedIn: 'root',
})
export class RouteController extends BaseController {
    private base = '/route'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getRoute(routeId: number): Observable<RouteCardDto> {
        return this.internal_get(this.base + "/card/" + routeId);
    }

    public getRouteByReadUuid(routeId: string): Observable<RouteCardDto> {
        return this.get(this.base + "/card/r/" + routeId);
    }

    public getRouteByWriteUuid(routeId: string): Observable<RouteCardDto> {
        return this.get(this.base + "/card/w/" + routeId);
    }

    public getRouteIdByRouteKey(routeKey: string): Observable<number> {
        return this.get(this.base + "/routeid/key/" + super.encode(routeKey));
    }

    public activeroutes(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeroutes", maintenanceSearchByText, "activeroutes");
        return this.post(this.base + "/activeroutes", maintenanceSearchByText, "activeroutes");
    }

    public activeslots(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeslots", maintenanceSearchByText, "activeslots");
        return this.post(this.base + "/activeslots", maintenanceSearchByText, "activeslots");
    }

    public getFilterByFilterType(filterType: string): Observable<FilterStripDto> {
        return this.get(this.base + "/filter/" + filterType);
    }

    public activeroutesExactKey(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeroutes/exactkey", maintenanceSearchByText, "activeroutes");
        return this.post(this.base + "/activeroutes/exactkey", maintenanceSearchByText, "activeroutes");
    }

    public activeslotsExactKey(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<RouteCardItemListDto> {
        if (busy)
            return this.postBusy(this.base + "/activeslots/exactkey", maintenanceSearchByText, "activeslots/exactkey");
        return this.post(this.base + "/activeslots/exactkey", maintenanceSearchByText, "activeslots/exactkey");
    }

    public getRouteCardItemExpand(routeId: number): Observable<RouteCardItemExpandDto> {
        return this.get(this.base + "/routecarditemexpand/" + routeId);
    }

    public getRoutePartnerExwork(routeId: number): Observable<RoutePartnerExworkDto> {
        return this.get(this.base + "/routepartnerexwork/" + routeId);
    }

    public activeanddoneroutes(maintenanceSearchByText: MaintenanceSearchByTextDto, busy: boolean): Observable<Array<PanelStateRoutesColumnDto>> {
        if (busy)
            return this.postBusy(this.base + "/activeanddoneroutes", maintenanceSearchByText, "activeanddoneroutes");
        return this.post(this.base + "/activeanddoneroutes", maintenanceSearchByText, "activeanddoneroutes");
    }

    public importRoute(body: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusyNoCancel(this.base + "/import", body, "importRoute" + body.fileName);
    }

    public importRouteGroup(body: FileUpDownLoadDto): Observable<boolean> {
        return this.postBusy(this.base + "/importgroup", body, "importRouteGroup");
    }

    public getRouteItemMaintenanceCtrl(routeId: number): Observable<RouteItemMaintenanceCtrlDto> {
        return this.post(this.base + "/routeitemmaintenance", routeId, "getRouteItemMaintenanceCtrl");
    }

    public getRouteLogs(routeId: number, filter: FilterLogDto): Observable<Array<RouteLogDto>> {
        return this.post(this.base + "/logs/" + routeId, filter, "getRouteLogs");
    }

    public computePhases(routeId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/computephases", routeId, "computePhases");
    }
    public computeStrongAddress(routeId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/computestrongaddress", routeId, "computeStrongAddress");
    }
    public groupActualizeOriginDestinationMaxLastMoment(routeId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/groupactualizeorigindestinationmaxlastmoment", routeId, "groupActualizeOriginDestinationMaxLastMoment");
    }

    public formatAddressesAndUpdatePhasesByRouteId(routeId: number): Observable<boolean> {
        return this.get(this.base + "/formataddressesandupdatephases/" + routeId);
    }

    public sortPhases(routeId: number): Observable<RouteCardDto> {
        this.translateService.get("ROUTECONTROLLER.SORTPHASES.THISWILLTAKEAFEWSECONDS", null).subscribe(m => { this.alertService.alert(AlertTypes.Info, m, false); });
        return this.postBusy(this.base + "/sortphases", routeId, "sortPhases");
    }
    public calculateTheoretical(routeId: number): Observable<RouteCardDto> {
        return this.post(this.base + "/calculatetheoretical", routeId, "calculateTheoretical");
    }
    public setasobserver(routeId: number): Observable<RouteCardDto> {
        return this.post(this.base + "/setasobserver/" + routeId + "/true", null, "setasobserver");
    }
    public unsetasobserver(routeId: number): Observable<RouteCardDto> {
        return this.post(this.base + "/setasobserver/" + routeId + "/false", null, "unsetasobserver");
    }
    public delayed(routeId: number): Observable<RouteCardDto> {
        return this.post(this.base + "/delayed/" + routeId + "/true", null, "delayed");
    }
    public nondelayed(routeId: number): Observable<RouteCardDto> {
        return this.post(this.base + "/delayed/" + routeId + "/false", null, "nondelayed");
    }
    public notifyToDriver(routeId: number): Observable<Boolean> {
        return this.post(this.base + "/notifydriver", routeId, "notifyToDriver");
    }
    public notifyToCarrier(routeId: number): Observable<Boolean> {
        return this.post(this.base + "/notifycarrier", routeId, "notifyToCarrier");
    }
    public getPartnerTypeForced(routeId: number): Observable<RoutePartnerTypesForcedBlockedDto> {
        return this.get(this.base + "/partnertypeforced/" + routeId);
    }
    public setPartnerTypeForced(routeId: number, data: SetRoutePartnerTypeForcedDto): Observable<RoutePartnerTypesForcedBlockedDto> {
        return this.post(this.base + "/setpartnertypeforced/" + routeId, data, "setPartnerTypeForced")
    }
    public routeDivision(routeId: number, numchilds: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/division/" + routeId, numchilds, "routeDivision");
    }
    public addChildOfDivision(routeId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/addchildofdivision/" + routeId, null, "addChildOfDivision");
    }
    public routeCompact(routeId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/compact/" + routeId, null, "routeCompact");
    }
    public deleteChildOfDivision(routeId: number, childOfDivisionId: number): Observable<RouteCardDto> {
        return this.postBusy(this.base + "/deletechildofdivision/" + routeId, childOfDivisionId, "deleteChildOfDivision");
    }

    public getRouteStates(routeId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/states/" + routeId);
    }

    public getMapInfoByRouteId(routeId: number): Observable<RouteMapInfoDto> {
        return this.get(this.base + "/map-info/" + routeId);
    }

    public routeWizard(routewizard: RouteWizardDto): Observable<RouteWizardDto> {
        return this.postBusy(this.base + "/wizard", routewizard, "routeWizard");
    }

    public validarRouteKey(routeKey: string): Observable<Boolean> {
        return this.post(this.base + "/wizardvalidateroutekey", routeKey, "validarRouteKey");
    }

    public resolveReadWriteUuid(uuid: string): Observable<RouteUuidTypeDto> {
        return this.get(this.base + "/resolveuuid/" + uuid);
    }

    public refreshUuids(routeId: number): Observable<boolean> {
        return this.get(this.base + "/refreshuuids/" + routeId);
    }

    public printpdf(routeId: number): Observable<FileUpDownLoadDto> {
        return this.getBusy(this.base + "/pdf/" + routeId);
    }

    ////////////////////////////// Endpoints Mobile //////////////////////////////

    public searchMobile(maintenanceSearchByText: MaintenanceSearchByTextDto): Observable<RouteListDto> {
        return this.post(this.base + "/mobile/search", maintenanceSearchByText, "searchMobile");
    }

    public getRouteActiveProcessOptions(phaseId: number): Observable<OptionsStripDto> {
        return this.get(this.base + "/mobile/activeroute/optionsprocess/" + phaseId);
    }

    public getRouteStatesMobile(routeId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/mobile/states/" + routeId);
    }

    public getRouteDriverStatesMobile(routeId: number): Observable<StatusTransitionsDto> {
        return this.get(this.base + "/mobile/driver/states/" + routeId);
    }

    public executeAdditionalActionMobile(routeId: number, action: string): Observable<Boolean> {
        return this.post(this.base + "/mobile/additionalaction/execute/" + routeId, action, "executeAdditionalActionMobile");
    }

    ////////////////////////////////////////////////////////////


    public wizardStart(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/start", route, "wizardStart");
    }
    public wizardRefreshDefaultDataByPartnerReverseLogistics(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/refreshdefaultDatabypartnerreverselogistics", route, "wizardRefreshDefaultDataByPartnerReverseLogistics");
    }
    public wizardNext(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/next", route, "wizardNext");
    }
    public wizardPrevious(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/previous", route, "wizardPrevious");
    }
    public wizardFinish(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<boolean> {
        return this.post(this.base + "/wizard/finish", route, "wizardFinish");
    }
    public wizardRemoveShipment(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/shipment/remove", route, "wizardRemove");
    }
    public wizardAddShipment(route: RouteReturnInBoundOutBoundDelegatedDto): Observable<RouteReturnInBoundOutBoundDelegatedDto> {
        return this.post(this.base + "/wizard/shipment/add", route, "wizardAddShipment");
    }

    /////////////////////// BOOKING ////////////////////////////////
    public getBookingOptions(routeId: number): Observable<OptionsStripDto> {
        return this.get(this.base + "/" + routeId + "/booking/options");
    }
    public getRouteContainerData(routeId: number): Observable<RouteContainerDataDto> {
        return this.get(this.base + "/" + routeId + "/routecontainerdata");
    }
    public changeRouteContainerData(data: RouteContainerDataDto): Observable<boolean> {
        return this.post(this.base + "/changeroutecontainerdata", data, "changeRouteContainerData");
    }

    //////////////////////// EXWORK ////////////////////////
    public getRouteExworkInfo(routeId: number): Observable<RouteExworkInfoDto> {
        return this.get(this.base + "/" + routeId + "/exwork");
    }

    public getRouteExworkLog(routeId: number): Observable<RouteExworkLogDto> {
        return this.get(this.base + "/" + routeId + "/exwork/log");
    }

    //////////////////////// ROUTE GROUP /////////////////////////
    public getRouteGroup(routeId: number): Observable<RouteGroupDto> {
        return this.get(this.base + "/group/" + routeId);
    }
    public routeGroupCreate(route: RouteGroupDto): Observable<RouteGroupDto> {
        return this.postBusy(this.base + "/group/create", route, "routeGroupCreate");
    }
    public routeGroupActualized(route: RouteGroupDto): Observable<RouteGroupDto> {
        return this.postBusy(this.base + "/group/actualized", route, "routeGroupActualized");
    }
    public searchActiveRouteCanChildsRouteGroupByText(searchText: string): Observable<RouteCardItemDto[]> {
        return this.post(this.base + "/group/searchchilds", searchText, "searchActiveRouteCanChildsRouteGroupByText");
    }
    public getActiveRoutesGroup(): Observable<RouteCardItemDto[]> {
        return this.get(this.base + "/group/activeroutesgroup");
    }

    public loadChildsRouteGroupDTOByFile(body: FileUpDownLoadDto): Observable<RouteGroupDto> {
        return this.postBusy(this.base + "/group/loadchildsroutegroupdtobyfile", body, "loadChildsRouteGroupDTOByFile");
    }

    public canRouteBeGrouped(routeIdToValidate: number): Observable<boolean> {
        return this.post(this.base + "/group/canroutebegrouped", routeIdToValidate, "canRouteBeGrouped");
    }

    public getRouteParentGroupIdByRouteId(routeId: number): Observable<number> {
        return this.get(this.base + "/routeparentgroupid/" + routeId);
    }

    public getRouteGroupChildListByPhaseId(phaseId: number): Observable<RouteGroupChildListDto> {
        return this.get(this.base + "/group/childlist/byphase/" + phaseId);
    }
    public getRouteGroupChildListByRouteId(routeId: number): Observable<RouteGroupChildListDto> {
        return this.get(this.base + "/group/childlist/byroute/" + routeId);
    }



    /////////////////////////////////////////////
    public getStatusTimeById(routeId: any): Observable<RouteStatusTimeDto> {
        return this.get(this.base + "/statustime/routeid/" + routeId);
    }

    executeAction(routeId: number, actionId: string) {
        return this.putBusy(this.base + "/executeaction/" + routeId + "/" + actionId, "", "executeRouteAction");
    }

    getDividedActionsAndOptions(routeId: number): Observable<ActionsAndOptionsDto> {
        return this.get(this.base + "/divided/actionsandoptions/" + routeId);
    }
    getDividedInfo(routeId: number): Observable<RouteDividedInfoDto> {
        return this.get(this.base + "/divided/info/" + routeId);
    }

}
