import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { SecurityController } from '@shared/src/controllers/security/security.controller';
import { BaseController } from '@shared/src/controllers/base.controller';
import { AlertService } from '@shared/src/services/alert/alert.service';
import { LoadingService } from '@shared/src/services/loading/loading.service';
import { AccessControlLogCtrlDto } from '@shared/src/dtos/accesscontrol/log/AccessControlLogCtrlDto';
import { FilterAccessControlListsDto } from '@shared/src/dtos/accesscontrol/filter/FilterAccessControlListsDto';
import { AccessControlCtrlDto } from '@shared/src/dtos/accesscontrol/AccessControlCtrlDto';
import { SetPlateGateDto } from '@shared/src/dtos/accesscontrol/SetPlateGateDto';
import { FilterAccessControlDto } from '@shared/src/dtos/accesscontrol/FilterAccessControlDto';
import { AccessControlBookCtrlDto } from '@shared/src/dtos/accesscontrol/book/AccessControlBookCtrlDto';
import { FileUpDownLoadDto } from '@shared/src/dtos/base/FileUpDownLoadDto';
import { PlateRouteDto } from '@shared/src/dtos/accesscontrol/PlateRouteDto';
import { AccessControlGatePlateInfoDto } from '@shared/src/dtos/accesscontrol/gate/plate/AccessControlGatePlateInfoDto';
import { FilterAccessControlExternalDto } from '@shared/src/dtos/accesscontrol/external/FilterAccessControlExternalDto';
import { AccessControlExternalDto } from '@shared/src/dtos/accesscontrol/external/AccessControlExternalDto';
import { AccessControlExternalMenuSettingsDto } from '@shared/src/dtos/accesscontrol/external/AccessControlExternalMenuSettingsDto';
import { AccessControlPageCtrlDto } from '@shared/src/dtos/accesscontrol/AccessControlPageCtrlDto';
import { AccessControlGatePlateDto } from '@shared/src/dtos/accesscontrol/gate/plate/AccessControlGatePlateDto';
import { AmbitAddressClassificationAddressDTO } from '../ambit/ambit.controller';
import { ActionDto } from '@shared/src/dtos/maintenance/action/actionDto';
import { AccessControlStatusDto } from './AccessControlStatusDto';


@Injectable({
    providedIn: 'root',
})
export class AccessControlController extends BaseController {
    private base = '/accesscontrol'

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router
    ) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getAccessControlConfig(): Observable<AccessControlPageCtrlDto> {
        return this.get(this.base + "/accesscontrolconfig");
    }
    public getAccessControlConfigAmbId(accesscontrolid: number): Observable<AccessControlPageCtrlDto> {
        return this.get(this.base + "/accesscontrolconfig/" + accesscontrolid);
    }
    public executeAccessControlAction(accesscontrolid: number, actionId: string): Observable<Boolean> {
        return this.postBusy(this.base + "/executeaction/" + accesscontrolid + "/" + actionId, null, "executeAccessControlAction");
    }
    public getAccessControlSettings(accesscontrolid: number, zones: string, size: number): Observable<AccessControlExternalMenuSettingsDto> {
        return this.get(this.base + "/accesscontrolsettings/" + accesscontrolid + "/" + zones + "/" + size);
    }

    public getAccessControl(filter: FilterAccessControlDto, busy: boolean): Observable<AccessControlCtrlDto> {
        if (busy)
            return this.postBusy(this.base + "/", filter, "getAccessControl");
        return this.post(this.base + "/", filter, "getAccessControl");
    }
    public getAccessControlExactKey(filter: FilterAccessControlDto, searchText: string): Observable<AccessControlCtrlDto> {
        return this.post(this.base + "/exactkey/" + super.encode(searchText), filter, "getAccessControlExactKey");
    }

    public getAccessControlExternal(filter: FilterAccessControlExternalDto): Observable<AccessControlExternalDto> {
        return this.post(this.base + "/external", filter, "getAccessControlExternal");
    }

    public getPlateRoute(dto: PlateRouteDto): Observable<AccessControlGatePlateInfoDto> {
        return this.post(this.base + "/getplateroute", dto, "getPlateRoute");
    }

    public exitEntryPlateGate(dto: SetPlateGateDto): Observable<Boolean> {
        return this.postBusy(this.base + "/exitentryplategate", dto, "exitEntryPlateGate");
    }

    public exitPlateOfAllGatesAndInitPhase(dto: SetPlateGateDto): Observable<Boolean> {
        return this.postBusy(this.base + "/exitplateofallgatesandinitphase", dto, "exitPlateOfAllGatesAndInitPhase");
    }
    public executeAction(dto: SetPlateGateDto, action: ActionDto): Observable<Boolean> {
        return this.postBusy(this.base + "/executeaction/" + action.actionId, dto, "executeAction");
    }

    public exitPlateOfAllGates(dto: SetPlateGateDto): Observable<Boolean> {
        return this.postBusy(this.base + "/exitplateofallgates", dto, "exitPlateOfAllGates");
    }

    public entryPlateGate(dto: SetPlateGateDto): Observable<Boolean> {
        return this.postBusy(this.base + "/entryplategate", dto, "entryPlateGate");
    }

    public exitPlateGate(dto: SetPlateGateDto): Observable<Boolean> {
        return this.postBusy(this.base + "/exitplategate", dto, "exitPlateGate");
    }

    public getAccessControlLogs(filter: FilterAccessControlListsDto): Observable<AccessControlLogCtrlDto> {
        return this.post(this.base + "/log", filter, "getAccessControlLogs");
    }

    public getAccessControlLogExcel(filter: FilterAccessControlListsDto): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/log/excel/", filter, "getAccessControlLogExcel");
    }

    public getAccessControlBook(filter: FilterAccessControlListsDto): Observable<AccessControlBookCtrlDto> {
        return this.post(this.base + "/book", filter, "getAccessControlBook");
    }

    public getAccessControlBookPdf(filter: FilterAccessControlListsDto): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/book/pdf/", filter, "getAccessControlBookPdf");
    }

    public getAccessControlBookExcel(filter: FilterAccessControlListsDto): Observable<FileUpDownLoadDto> {
        return this.post(this.base + "/book/excel/", filter, "getAccessControlBookExcel");
    }

    public getAccessControlAddressClassification(classification: string): Observable<AccessControlAddressClassificationDTO> {
        return this.get(this.base + "/address/classification/" + classification);
    }
    public setAccessControlAddressClassification(classification: string, accessControlId: number, addressId: number): Observable<boolean> {
        return this.post(this.base + "/address/classification/" + classification, { 'accessControlId': accessControlId, 'addressId': addressId }, "setAccessControlAddressClassification");
    }

    public getAccessControlStatus(): Observable<Array<AccessControlStatusDto>> {
        return this.get(this.base + "/status");
    }


    public compareAccessControl(a: AccessControlAddressClassificationAccessControlDTO, b: AccessControlAddressClassificationAccessControlDTO) {
        if (a.selected)
            return 1;
        if (a.score < b.score)
            return -1;
        if (a.score > b.score)
            return 1;
        if (a.accessControlId < b.accessControlId)
            return -1;
        return 1;
    }
    public compareAddress(a: AccessControlAddressClassificationAddressDTO, b: AccessControlAddressClassificationAddressDTO) {
        if (a.selected)
            return 1;
        if (a.score < b.score)
            return -1;
        if (a.score > b.score)
            return 1;
        if (a.addressId < b.addressId)
            return -1;
        return 1;
    }
}

export class AccessControlAddressClassificationDTO {
    public accessControlList: Array<AccessControlAddressClassificationAccessControlDTO>;
    public defaultAccessControlId: number;
    public defaultAddressId: number;
}

export class AccessControlAddressClassificationAccessControlDTO {
    public accessControlId: number;
    public accessControlKey: string;
    public description: string;
    public score: number;
    public selected: boolean;

    public addressList: Array<AccessControlAddressClassificationAddressDTO>
}

export class AccessControlAddressClassificationAddressDTO extends AmbitAddressClassificationAddressDTO {
}
