import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from "@shared/src/services/alert/alert.service";
import { LoadingService } from "@shared/src/services/loading/loading.service";
import { Observable } from 'rxjs';

import { SimpleTableDto } from "@shared/src/components/core/canvas/SimpleTableDto";
import { HDateHour } from "@shared/src/datatypes/HDateHour";
import { FileUpDownLoadDto } from "@shared/src/dtos/base/FileUpDownLoadDto";
import { ActionsStripDto } from "@shared/src/dtos/maintenance/action/ActionsStripDto";
import { FilterStatisticsDto } from "@shared/src/dtos/statistics/FilterStatisticsDto";
import { StatisticsDto } from "@shared/src/dtos/statistics/StatisticsDto";
import { BaseController } from "../base.controller";
import { SecurityController } from "../security/security.controller";
import { JourneyTableDto } from "./JourneyTableDto";
import { ResultRoutePhaseTimesTableDto } from "./ResultRoutePhaseTimesTableDto";
import { ResultRoutePhaseTimesTableFilterDto } from "./ResultRoutePhaseTimesTableFilterDto";
import { ResultStatisticsDeliveryDto, ResultStatisticsWithBoxesDto } from "./ResultStatisticsDeliveryDto";
import { SlotsByCharacteristicsDto } from "./SlotsByCharacteristicsDto";
import { SlotsByCharacteristicsFilterDto } from "./SlotsByCharacteristicsFilterDto";
import { SlotsByTagsDto } from "./SlotsByTagsDto";
import { SlotsByTagsFilterDto } from "./SlotsByTagsFilterDto";
import { SlotsKPIDto } from "./SlotsKPIDto";
import { SlotsKPIFilterDto } from "./SlotsKPIFilterDto";
import { SlotsMovementsDto } from "./SlotsMovementsDto";
import { SlotsMovementsFilterDto } from "./SlotsMovementsFilterDto";
import { StatisticsDeliveryFilterDto } from "./StatisticsDeliveryFilterDto";
import { StatisticsExternalRoutePlanificationDto } from "./StatisticsExternalRoutePlanificationDto";
import { StatisticsExternalRoutePlanificationFilterDto } from "./StatisticsExternalRoutePlanificationFilterDto";
import { StatisticsInternationalControlContinuousDto } from "./StatisticsInternationalControlContinuousDto";
import { StatisticsInternationalControlContinuousFilterDto } from "./StatisticsInternationalControlContinuousFilterDto";
import { StatisticsInternationalControlDto } from "./StatisticsInternationalControlDto";
import { StatisticsInternationalControlFilterDto } from "./StatisticsInternationalControlFilterDto";
import { StatisticsInternationalPlanificationDto } from "./StatisticsInternationalPlanificationDto";
import { StatisticsInternationalPlanificationFilterDto } from "./StatisticsInternationalPlanificationFilterDto";
import { StatisticsSlotsDto } from "./StatisticsSlotsDto";
import { StatisticsSlotsPlanificationByDateDto } from "./StatisticsSlotsPlanificationByDateDto";
import { StatisticsSlotsPlanificationByDateFilterDto } from "./StatisticsSlotsPlanificationByDateFilterDto";
import { StatisticsSlotsPlanificationByDockDto } from "./StatisticsSlotsPlanificationByDockDto";
import { StatisticsSlotsPlanificationByDockFilterDto } from "./StatisticsSlotsPlanificationByDockFilterDto";
import { StatisticsStayTimeDto } from "./StatisticsStayTimeDto";
import { StatisticsStayTimesFilterDto } from "./StatisticsStayTimesFilterDto";
import { StatusTableAddressResultDto } from "./StatusTableAddressResultDto";
import { ResultStatisticsSlotsPartnerDTO } from "./ResultStatisticsSlotsPartnersDto";
import { FilterStatisticsPartnerDto } from "@shared/src/dtos/statistics/FilterStatisticsPartnerDto";
import { FilterStatisticsPlateCharacteristicsDto } from "@shared/src/dtos/statistics/FilterStatisticsPlateCharacteristicsDto";
import { FilterStatisticsTelemetryByPlateDto } from "@shared/src/controllers/statistics/telemetry/FilterStatisticsTelemetryByPlateDto";
import { ResultStatisticsTelemetryByPlateDto } from "@shared/src/controllers/statistics/telemetry/ResultStatisticsTelemetryByPlateDto";
import { FilterStatisticsTelemetryByRouteDto } from "@shared/src/controllers/statistics/telemetry/FilterStatisticsTelemetryByRouteDto";
import { ResultStatisticsTelemetryByRouteDto } from "@shared/src/controllers/statistics/telemetry/ResultStatisticsTelemetryByRouteDto";
import { FilterStatisticsTelemetryByShipmentDto } from "@shared/src/controllers/statistics/telemetry/FilterStatisticsTelemetryByShipmentDto";
import { ResultStatisticsTelemetryByShipmentDto } from "@shared/src/controllers/statistics/telemetry/ResultStatisticsTelemetryByShipmentDto";
import { AccessControlBookCtrlDto } from "@shared/src/dtos/accesscontrol/book/AccessControlBookCtrlDto";
import { FilterAccessControlListsDto } from "@shared/src/dtos/accesscontrol/filter/FilterAccessControlListsDto";
import { FilterStatisticsPartnerByPermissionsDto } from "@shared/src/dtos/statistics/FilterStatisticsPartnerByPermissionsDto";
import { StatisticsInternationalLoadsContainerFilterDto } from "./StatisticsInternationalLoadsContainerFilterDto";
import { StatisticsInternationalLoadsContainerDto } from "./StatisticsInternationalLoadsContainerDto";
import { AccessControlLogCtrlDto } from "@shared/src/dtos/accesscontrol/log/AccessControlLogCtrlDto";
import { StatisticsInternationalContainerControlFilterDto } from "./container/StatisticsInternationalContainerControlFilterDto";
import { StatisticsInternationalContainerControlDto } from "./container/StatisticsInternationalContainerControlDto";
import { StatisticsTelemetryActualTemperaturesFilterDto } from "./telemetry/temperatures/StatisticsTelemetryActualTemperaturesFilterDto";
import { StatisticsSlotsExternalControlDto } from "./slot/StatisticsSlotsExternalControlDto";
import { StatisticsSlotsExternalControlFilterDto } from "./slot/StatisticsSlotsExternalControlFilterDto";
import { ResultStatisticsDeliveryPartnerDto } from "./ResultStatisticsDeliveryPartnerDto";
import { StatisticsDeliveryPartnerFilterDto } from "./StatisticsDeliveryPartnerFilterDto";
import { ResultStatisticsRoutesNoSlotFilterDto } from "./routesnoslot/ResultStatisticsRoutesNoSlotFilterDto";
import { ResultStatisticsRoutesNoSlotDto } from "./routesnoslot/ResultStatisticsRoutesNoSlotDto";
import { StatisticsInternationalRoutesDoneFilterDto } from "./StatisticsInternationalRoutesDoneFilterDto";
import { StatisticsInternationalRoutesDoneDto } from "./StatisticsInternationalRoutesDoneDto";
import { StatisticsInternationalRoutesDoneContinuousFilterDto } from "./StatisticsInternationalRoutesDoneContinuousFilterDto";
import { StatisticsInternationalRoutesDoneContinuousDto } from "./StatisticsInternationalRoutesDoneContinuousDto";

@Injectable({
    providedIn: 'root',
})
export class StatisticsController extends BaseController {

    private base = '/statistics';

    constructor(
        @Inject('SecurityController') protected securityController: SecurityController,
        protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        protected router: Router,
        @Inject('LoadingService') protected loadingService: LoadingService) {
        super(http, alertService, translateService, loadingService, router);
    }

    public getStatistics(filter: FilterStatisticsDto): Observable<StatisticsDto> {
        return this.postBusy(this.base + "/statistics", filter, "getStatistics");
    }
    public getStatusTable(addressId: number, ambitId: number, dateHourStart: HDateHour, dateHourEnd: HDateHour, selectedRouteStatus: String[], selectedPhaseStatus: String[]): Observable<StatusTableAddressResultDto> {
        return this.postBusy(this.base + "/statustableaddress", { 'addressId': addressId, 'ambitId': ambitId, 'dateHourStart': dateHourStart, 'dateHourEnd': dateHourEnd, 'selectedRouteStatus': selectedRouteStatus, 'selectedPhaseStatus': selectedPhaseStatus }, "getStatusTable");
    }

    public downloadStatusTable(addressId: number, ambitId: number, dateHourStart: HDateHour, dateHourEnd: HDateHour): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/download-statustable", { 'addressId': addressId, 'ambitId': ambitId, 'dateHourStart': dateHourStart, 'dateHourEnd': dateHourEnd }, "downloadStatusTable");
    }
    public getSlots(filter: FilterStatisticsDto): Observable<StatisticsSlotsDto> {
        return this.postBusy(this.base + "/slots", filter, "getSlots");
    }
    public getSlotsDownload(filter: FilterStatisticsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slots/download", filter, "getSlotsDownload");
    }

    public getSlotsByPartner(filter: FilterStatisticsPartnerDto): Observable<ResultStatisticsSlotsPartnerDTO> {
        return this.postBusy(this.base + "/slotsbypartner", filter, "getSlotsByPartner");
    }
    public getSlotsByPartnerDownload(filter: FilterStatisticsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsbypartner/download", filter, "getSlotsDownload");
    }

    public getStatisticsStayTime(filter: StatisticsStayTimesFilterDto): Observable<StatisticsStayTimeDto> {
        return this.postBusy(this.base + "/staytime", filter, "staytime");
    }
    public getStatisticsStayTimeDownload(filter: StatisticsStayTimesFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/staytime/download", filter, "staytimeDownload");
    }

    public getSlotsByCharacteristics(filter: SlotsByCharacteristicsFilterDto): Observable<SlotsByCharacteristicsDto> {
        return this.postBusy(this.base + "/slotsbycharacteristics", filter, "slotsbycharacteristics");
    }
    public getSlotsByCharacteristicsDownload(filter: SlotsByCharacteristicsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsbycharacteristics/download", filter, "slotsbycharacteristicsdownload");
    }

    public getSlotsByTags(filter: SlotsByTagsFilterDto): Observable<SlotsByTagsDto> {
        return this.postBusy(this.base + "/slotsbytags", filter, "slotsbytags");
    }
    public getSlotsByTagsDownload(filter: SlotsByTagsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsbytags/download", filter, "slotsbytagsdownload");
    }

    public getSlotsMovements(filter: SlotsMovementsFilterDto): Observable<SlotsMovementsDto> {
        return this.postBusy(this.base + "/slotsmovements", filter, "slotsmovements");
    }
    public getSlotsMovementsDownload(filter: SlotsMovementsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsmovements/download", filter, "slotsmovementsdownload");
    }

    public getSlotsKPI(filter: SlotsKPIFilterDto): Observable<SlotsKPIDto> {
        return this.postBusy(this.base + "/slotskpi", filter, "slotskpi");
    }


    public getPhaseTimesTable(filter: ResultRoutePhaseTimesTableFilterDto): Observable<ResultRoutePhaseTimesTableDto> {
        return this.putBusy(this.base + "/phasetimestable", filter, "getPhaseTimesTable");
    }

    public downloadPhaseTimesTable(filter: ResultRoutePhaseTimesTableFilterDto): Observable<FileUpDownLoadDto> {
        return this.putBusy(this.base + "/phasetimestable/download", filter, "downloadPhaseTimesTable");
    }

    public getStatistisDelivery(filter: StatisticsDeliveryFilterDto): Observable<ResultStatisticsDeliveryDto> {
        return this.postBusy(this.base + "/statisticsdelivery", filter, "getStatistisDelivery");
    }
    public downloadStatistisDelivery(filter: StatisticsDeliveryFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/statisticsdelivery/download", filter, "downloadStatistisDelivery");
    }

    public getStatistisDeliveryPartner(filter: StatisticsDeliveryPartnerFilterDto, busy: boolean): Observable<ResultStatisticsDeliveryPartnerDto> {
        if (busy)
            return this.postBusy(this.base + "/statisticsdeliverypartner", filter, "getStatistisDeliveryPartner");
        return this.post(this.base + "/statisticsdeliverypartner", filter, "getStatistisDeliveryPartner");
    }
    public downloadStatistisDeliveryPartner(filter: StatisticsDeliveryPartnerFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/statisticsdeliverypartner/download", filter, "downloadStatistisDeliveryPartner");
    }


    public getJourneyTable(journeyTableDto: JourneyTableDto): Observable<JourneyTableDto> {
        return this.postBusy(this.base + "/journeytable", journeyTableDto, "getJourneyTable");
    }

    public getJourneyTablePdf(data: JourneyTableDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/download-journeytable/pdf", data, "getJourneyTablePdf");
    }

    public getJourneyTableExcel(data: JourneyTableDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/download-journeytable/excel", data, "getJourneyTableExcel");
    }

    public getInternationalControl(filter: StatisticsInternationalControlFilterDto, busy: boolean): Observable<StatisticsInternationalControlDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalcontrol", filter, "getInternationalControl");
        return this.post(this.base + "/internationalcontrol", filter, "getInternationalControl");
    }
    public downloadInternationalControl(filter: StatisticsInternationalControlFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalcontrol/download", filter, "downloadInternationalControl");
    }

    public getInternationalRoutesDone(filter: StatisticsInternationalRoutesDoneFilterDto, busy: boolean): Observable<StatisticsInternationalRoutesDoneDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalroutesdone", filter, "getroutesdone");
        return this.post(this.base + "/internationalroutesdone", filter, "getInternationalControl");
    }

    public getInternationalRoutesDoneContinuous(filter: StatisticsInternationalRoutesDoneFilterDto, busy: boolean): Observable<StatisticsInternationalRoutesDoneDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalroutesdone", filter, "getroutesdone");
        return this.post(this.base + "/internationalroutesdone", filter, "getInternationalControl");
    }

    public downloadInternationalRoutesDone(filter: StatisticsInternationalRoutesDoneFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalroutesdone/download", filter, "downloadroutesdone");
    }

    public downloadInternationalRoutesDoneContinuous(filter: StatisticsInternationalRoutesDoneFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalroutesdone/download", filter, "downloadroutesdone");
    }



    public getInternationalControlContinuous(filter: StatisticsInternationalControlContinuousFilterDto, busy: boolean): Observable<StatisticsInternationalControlContinuousDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalcontrolcontinuous", filter, "getInternationalControlContinuous");
        return this.post(this.base + "/internationalcontrolcontinuous", filter, "getInternationalControlContinuous");
    }
    public downloadInternationalControlContinuous(filter: StatisticsInternationalControlContinuousFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalcontrolcontinuous/download", filter, "downloadInternationalControlContinuous");
    }

    public getInternationalPlanification(filter: StatisticsInternationalPlanificationFilterDto, busy: boolean): Observable<StatisticsInternationalPlanificationDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalplanification", filter, "getInternationalPlanification");
        return this.post(this.base + "/internationalplanification", filter, "getInternationalPlanification");
    }
    public downloadInternationalPlanification(filter: StatisticsInternationalPlanificationFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalplanification/download", filter, "downloadInternationalPlanification");
    }

    public getInternationalLoadsContainer(filter: StatisticsInternationalLoadsContainerFilterDto, busy: boolean): Observable<StatisticsInternationalLoadsContainerDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalloadscontainer", filter, "getInternationalLoadsContainer");
        return this.post(this.base + "/internationalloadscontainer", filter, "getInternationalLoadsContainer");
    }
    public downloadInternationalLoadsContainer(filter: StatisticsInternationalLoadsContainerFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalloadscontainer/download", filter, "downloadInternationalLoadsContainer");
    }

    public getExternalRoutePlanification(filter: StatisticsExternalRoutePlanificationFilterDto, busy: boolean): Observable<StatisticsExternalRoutePlanificationDto> {
        if (busy)
            return this.postBusy(this.base + "/externalrouteplanification", filter, "getExternalRoutePlanification");
        return this.post(this.base + "/externalrouteplanification", filter, "getExternalRoutePlanification");
    }
    public downloadExternalRoutePlanification(filter: StatisticsExternalRoutePlanificationFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/externalrouteplanification/download", filter, "downloadExternalRoutePlanification");
    }


    public getBookingSlots(filter: SlotsByCharacteristicsFilterDto): Observable<ResultStatisticsBookingSlotsDto> {
        return this.postBusy(this.base + "/bookingslots", filter, "getBookingSlots");
    }
    public getInternationalBookingSlots(filter: SlotsByCharacteristicsFilterDto): Observable<ResultStatisticsBookingSlotsDto> {
        return this.postBusy(this.base + "/internationalbookingslots", filter, "getInternationalBookingSlots");
    }
    public downloadBookingSlots(filter: SlotsByCharacteristicsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/bookingslots/download", filter, "downloadBookingSlots");
    }
    public downloadInternationalBookingSlots(filter: SlotsByCharacteristicsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalbookingslots/download", filter, "downloadInternationalBookingSlots");
    }
    public getBookingSlotsWithMemory(filter: SlotsByCharacteristicsFilterDto): Observable<ResultStatisticsBookingSlotsDto> {
        return this.postBusy(this.base + "/bookingslotswithmemory", filter, "getBookingSlotsWithMemory");
    }
    public downloadBookingSlotsWithMemory(filter: SlotsByCharacteristicsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/bookingslotswithmemory/download", filter, "downloadBookingSlotsWithMemory");
    }

    public getStatisticsTelemetryShipment(filter: FilterStatisticsDto): Observable<ResultStatisticsTelemetryShipmentDto> {
        return this.postBusy(this.base + "/telemetry/shipments", filter, "getStatisticsTelemetryShipment");
    }
    public downloadStatisticsTelemetryShipment(filter: FilterStatisticsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetry/shipments/download", filter, "downloadStatisticsTelemetryShipment");
    }

    public getFilterRoutesNoSlot(): Observable<ResultStatisticsRoutesNoSlotFilterDto> {
        return this.get(this.base + "/filterroutesnoslot");
    }
    public getRoutesNoSlot(filter: ResultStatisticsRoutesNoSlotFilterDto): Observable<ResultStatisticsRoutesNoSlotDto> {
        return this.postBusy(this.base + "/routesnoslot", filter, "getRoutesNoSlot");
    }
    public downloadRoutesNoSlot(filter: ResultStatisticsRoutesNoSlotFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/routesnoslot/download", filter, "downloadRoutesNoSlot");
    }

    public getRoutesNoBooking(filter: SlotsByCharacteristicsFilterDto): Observable<ResultStatisticsRoutesNoBookingDto> {
        return this.postBusy(this.base + "/routesnobooking", filter, "getRoutesNoBooking");
    }
    public downloadRoutesNoBooking(filter: SlotsByCharacteristicsFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/routesnobooking/download", filter, "downloadRoutesNoBooking");
    }


    public getSlotsPlanificationByDate(filter: StatisticsSlotsPlanificationByDateFilterDto, busy: boolean): Observable<StatisticsSlotsPlanificationByDateDto> {
        if (busy)
            return this.postBusy(this.base + "/slotsplanification/bydate", filter, "slotsPlanificationByDate");
        return this.post(this.base + "/slotsplanification/bydate", filter, "slotsPlanificationByDate");
    }
    public downloadSlotsPlanificationByDate(filter: StatisticsSlotsPlanificationByDateFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsplanification/bydate/download", filter, "slotsPlanificationByDate");
    }
    public getSlotsPlanificationByDock(filter: StatisticsSlotsPlanificationByDockFilterDto, busy: boolean): Observable<StatisticsSlotsPlanificationByDockDto> {
        if (busy)
            return this.postBusy(this.base + "/slotsplanification/bydock", filter, "slotsPlanificationByDock");
        return this.post(this.base + "/slotsplanification/bydock", filter, "slotsPlanificationByDock");
    }
    public downloadSlotsPlanificationByDock(filter: StatisticsSlotsPlanificationByDockFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsplanification/bydock/download", filter, "downloadInternationalControl");
    }

    public getPlateCharacteristics(filter: FilterStatisticsPlateCharacteristicsDto): Observable<ResultStatisticsPlateCharactaristicsDto> {
        return this.postBusy(this.base + "/platecharacteristics", filter, "getPlateCharacteristics");
    }
    public getPlateCharacteristicsDownload(filter: FilterStatisticsPlateCharacteristicsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/platecharacteristics/download", filter, "getPlateCharacteristicsDownload");
    }

    public getTelemetryByPlate(filter: FilterStatisticsTelemetryByPlateDto): Observable<ResultStatisticsTelemetryByPlateDto> {
        return this.postBusy(this.base + "/telemetry/byplate", filter, "getTelemetryByPlate");
    }
    public getTelemetryByPlateDownload(filter: FilterStatisticsTelemetryByPlateDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetry/byplate/download", filter, "getTelemetryByPlateDownload");
    }

    public getTelemetryByRoute(filter: FilterStatisticsTelemetryByRouteDto): Observable<ResultStatisticsTelemetryByRouteDto> {
        return this.postBusy(this.base + "/telemetry/byroute", filter, "getTelemetryByRoute");
    }
    public getTelemetryByRouteDownload(filter: FilterStatisticsTelemetryByRouteDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetry/byroute/download", filter, "getTelemetryByRouteDownload");
    }

    public getTelemetryByShipment(filter: FilterStatisticsTelemetryByShipmentDto): Observable<ResultStatisticsTelemetryByShipmentDto> {
        return this.postBusy(this.base + "/telemetry/byshipment", filter, "getTelemetryByShipment");
    }
    public getTelemetryByShipmentDownload(filter: FilterStatisticsTelemetryByShipmentDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetry/byshipment/download", filter, "getTelemetryByShipmentDownload");
    }

    public getAccessControlBook(filter: FilterAccessControlListsDto): Observable<AccessControlBookCtrlDto> {
        return this.postBusy(this.base + "/accesscontrol/book", filter, "getAccessControlBook");
    }
    public downloadAccessControlBook(filter: FilterAccessControlListsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/accesscontrol/book/download", filter, "downloadAccessControlBook");
    }

    public getAccessControlLog(filter: FilterAccessControlListsDto): Observable<AccessControlLogCtrlDto> {
        return this.postBusy(this.base + "/accesscontrol/log", filter, "getAccessControlLog");
    }
    public downloadAccessControlLog(filter: FilterAccessControlListsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/accesscontrol/log/download", filter, "downloadAccessControlLog");
    }

    public getPartnerByPermissions(filter: FilterStatisticsPartnerByPermissionsDto): Observable<ResultStatisticsPartnerPermissionsDto> {
        return this.postBusy(this.base + "/partnerbypermissions", filter, "getPartnerByPermissions");
    }
    public getPartnerByPermissionsDownload(filter: FilterStatisticsPartnerByPermissionsDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/partnerbypermissions/download", filter, "getPartnerByPermissionsDownload");
    }


    public getInternationalContainerControl(filter: StatisticsInternationalContainerControlFilterDto, busy: boolean): Observable<StatisticsInternationalContainerControlDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalcontainercontrol", filter, "getInternationalContainerControl");
        return this.post(this.base + "/internationalcontainercontrol", filter, "getInternationalContainerControl");
    }
    public downloadInternationalContainerControl(filter: StatisticsInternationalContainerControlFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalcontainercontrol/download", filter, "downloadInternationalContainerControl");
    }
    public getInternationalContainerContinousControl(filter: StatisticsInternationalContainerControlFilterDto, busy: boolean): Observable<StatisticsInternationalContainerControlDto> {
        if (busy)
            return this.postBusy(this.base + "/internationalcontainercontinouscontrol", filter, "getInternationalContainerContinousControl");
        return this.post(this.base + "/internationalcontainercontinouscontrol", filter, "getInternationalContainerContinousControl");
    }
    public downloadInternationalContainerContinousControl(filter: StatisticsInternationalContainerControlFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/internationalcontainercontinouscontrol/download", filter, "downloadInternationalContainerContinousControl");
    }



    public telemetryactualtemperatures(filter: StatisticsTelemetryActualTemperaturesFilterDto, busy: boolean): Observable<StatisticsInternationalContainerControlDto> {
        if (busy)
            return this.postBusy(this.base + "/telemetryactualtemperatures", filter, "telemetryactualtemperatures");
        return this.post(this.base + "/telemetryactualtemperatures", filter, "telemetryactualtemperatures");
    }
    public telemetryactualtemperaturesDownload(filter: StatisticsTelemetryActualTemperaturesFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetryactualtemperatures/download", filter, "telemetryactualtemperatures");
    }
    public telemetryactualtemperaturescontinuos(filter: StatisticsTelemetryActualTemperaturesFilterDto, busy: boolean): Observable<StatisticsInternationalContainerControlDto> {
        if (busy)
            return this.postBusy(this.base + "/telemetryactualtemperaturescontinuos", filter, "telemetryactualtemperaturescontinuos");
        return this.post(this.base + "/telemetryactualtemperaturescontinuos", filter, "telemetryactualtemperaturescontinuos");
    }
    public telemetryactualtemperaturescontinuosDownload(filter: StatisticsTelemetryActualTemperaturesFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/telemetryactualtemperaturescontinuos/download", filter, "telemetryactualtemperaturescontinuos");
    }


    public slotsexternalcontrol(filter: StatisticsSlotsExternalControlFilterDto, busy: boolean): Observable<StatisticsSlotsExternalControlDto> {
        if (busy)
            return this.postBusy(this.base + "/slotsexternalcontrol", filter, "slotsexternalcontrol");
        return this.post(this.base + "/slotsexternalcontrol", filter, "slotsexternalcontrol");
    }
    public slotsexternalcontrolDownload(filter: StatisticsSlotsExternalControlFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsexternalcontrol/download", filter, "slotsexternalcontrol");
    }
    public slotsexternalcontrolcontinuos(filter: StatisticsSlotsExternalControlFilterDto, busy: boolean): Observable<StatisticsSlotsExternalControlDto> {
        if (busy)
            return this.postBusy(this.base + "/slotsexternalcontrolcontinuos", filter, "slotsexternalcontrolcontinuos");
        return this.post(this.base + "/slotsexternalcontrolcontinuos", filter, "slotsexternalcontrolcontinuos");
    }
    public slotsexternalcontrolcontinuosDownload(filter: StatisticsSlotsExternalControlFilterDto): Observable<FileUpDownLoadDto> {
        return this.postBusy(this.base + "/slotsexternalcontrolcontinuos/download", filter, "slotsexternalcontrolcontinuos");
    }

}

export class ResultStatisticsRoutesNoBookingDto extends ResultStatisticsWithBoxesDto {
    actions: ActionsStripDto;
    tableWithBooking: SimpleTableDto;
    tableWithoutBooking: SimpleTableDto;
}
export class ResultStatisticsBookingSlotsDto extends ResultStatisticsWithBoxesDto {
    actions: ActionsStripDto;
    table: SimpleTableDto;
}
export class ResultStatisticsTelemetryShipmentDto extends ResultStatisticsWithBoxesDto {
    actions: ActionsStripDto;
    table: SimpleTableDto;
}

export class ResultStatisticsPlateCharactaristicsDto extends ResultStatisticsWithBoxesDto {
    table: SimpleTableDto;
}

export class ResultStatisticsPartnerPermissionsDto extends ResultStatisticsWithBoxesDto {
    table: SimpleTableDto;
}