import { FilterStripDto } from "@shared/src/components/core/maintenance/filter/FilterStripDtos";
import { TelemetryModel } from "@shared/src/enums/TelemetryModel";
import { TelemetryProvider } from "@shared/src/public-api";
import { DeviceDto } from "@shared/src/dtos/device/DeviceDto";
import { HDateHour } from "../../../../datatypes/HDateHour";
export class TelemetryFilterDto {

    public Model: TelemetryModel;
    public ModelId: number;
    public DateStart: HDateHour;
    public DateEnd: HDateHour;
    public filterStrip: FilterStripDto;
    public telemetryProvider: TelemetryProvider;
    public devices: Array<DeviceDto>;
    public dimensionsRequired: boolean = true;

    public static build(model: TelemetryModel, modelId: number, dateStart: HDateHour, dateEnd: HDateHour, filter: FilterStripDto, telemetryProvider: TelemetryProvider, devices: Array<DeviceDto>): TelemetryFilterDto {
        let result = new TelemetryFilterDto();
        result.Model = model;
        result.ModelId = modelId;
        result.DateStart = dateStart;
        result.DateEnd = dateEnd;
        result.filterStrip = filter;
        result.telemetryProvider = telemetryProvider;
        result.devices = devices;
        return result;
    }

    public static buildByFilter(source: TelemetryFilterDto) {
        let result = new TelemetryFilterDto();
        if (source != null) {
            result.Model = source.Model;
            result.ModelId = source.ModelId;
            result.DateStart = HDateHour.cloneByHDateHour(source.DateStart);
            result.DateEnd = HDateHour.cloneByHDateHour(source.DateEnd);
            result.filterStrip = FilterStripDto.buildFromFilterStripDto(source.filterStrip);
            result.telemetryProvider = source.telemetryProvider;
            result.devices = source.devices;
        }
        return result;
    }

    public static noDimensionsRequired(value: TelemetryFilterDto): TelemetryFilterDto {
        let result = TelemetryFilterDto.buildByFilter(value);
        result.dimensionsRequired = false;
        return result;
    }

    isValid() {
        if (this.Model != null && this.ModelId != null)
            return true;
        return false;
    }

}
