import { Component, Input, OnInit } from '@angular/core';
import { PartnersController } from '@shared/src/controllers/link/partners.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { PartnerInfoDto } from '@shared/src/dtos/link/PartnerInfoDto';
import { PartnerCategories } from '@shared/src/enums/PartnerCategories';

@Component({
  selector: 'shared-partner-infocard',
  templateUrl: './shared.partner.infocard.component.html',
  styleUrls: ['./shared.partner.infocard.component.scss']
})
export class SharedPartnerInfoCardComponent implements OnInit {

  constructor(protected partnersController: PartnersController) { }

  ngOnInit(): void { }

  @Input() type: PartnerCategories;
  @Input() caption: string = "PARTNER.TITLE";

  @Input() routeId: number;

  private _partnerId: number;
  @Input() public set partnerId(v: number) {
    if (this._partnerId === v)
      return;

    this._partnerId = v;
    if (!this._partnerId)
      this._partnerId = 0;

    if (this._partnerId) {
      if(HLong.isNullOrNullLong(this.routeId)){
        this.partnersController.getPartnerInfo(this.type, this._partnerId).subscribe(e => {
          this.partner = e;
        });
      }else {
        this.partnersController.getPartnerInfoRoute(this.type, this._partnerId, this.routeId).subscribe(e => {
          this.partner = e;
        });
      }
    } else {
      this.partner = null;
    }
  }

  public get partnerId(): number {
    return this._partnerId;
  }

  private _isParent: boolean;
  @Input() public set isParent(v: boolean) {
    if (this.isParent === v)
      return;

    if (!v)
      this.partner = null;
    else if (this.routeId)
      this.partnersController.getParentInfoRoute(this.routeId).subscribe(e => {
        if (e) {
          this.partner = e;
          this.caption = "PARTNER.PARENT.TITLE";
        }
      })
  }

  public get isParent(): boolean {
    return this._isParent;
  }

  @Input() partner: PartnerInfoDto;

}