<div class="documentacio incomplerta" [innerHTML]="'ROUTE.PART.INCOMPLETDOCUMENTATION.CAPTION'| translate"
  *ngIf="route_eCMR && route_eCMR.isIncompletDocument">
</div>
<options class="nomenu" [optionstrip]="options" (clickOption)="onClickOption($event)" *ngIf="!avoidOptions"></options>

<ng-container *ngIf="route_eCMR && !route_eCMR.CMRAsDocument && (option && option.optionId=='1')">
  <span class="ecmr-title">
    {{ route_eCMR.routePartCMRKey }}&nbsp;
    <shared-copyclipboard [value]="route_eCMR.routePartCMRKey"></shared-copyclipboard>
  </span>

  <div class="ecmr-block expeditor">
    <span class="ecmr-block-title">
      {{ 'ECMR.EXPEDITOR.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || originTenantBlank()) && !readonly" (click)="updateOriginTenant = true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || originTenantBlank()) && !readonly" (click)="resetOriginTenant()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepartner-dialog [expanded]="updateOriginTenant" title="ECMR.EXPEDITOR.TITLE" [routePartCMRId]="routePartCMRId"
      partnerType="origin" [tenantId]="route_eCMR.originTenantId" [name]="route_eCMR.originTenantName"
      [nif]="route_eCMR.originTenantNif" [formattedAddress]="route_eCMR.originTenantFormattedAddress"
      [contactName]="route_eCMR.originTenantContactName" [contactPhone]="route_eCMR.originTenantContactPhone"
      (onConfirm)="loadCMR()" (onCancel)="updateOriginTenant=false"></updatepartner-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.originTenantName || route_eCMR.originTenantNif">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.originTenantName }}
            <shared-copyclipboard [value]="route_eCMR.originTenantName"></shared-copyclipboard>
          </div>
        </div>
        <div class="ecmr-block-col">
          <div class="key">{{ route_eCMR.originTenantNif }}
            <shared-copyclipboard [value]="route_eCMR.originTenantNif"></shared-copyclipboard>
          </div>
        </div>
      </div>
      <div class="ecmr-block-row">
        <div class="ecmr-block-col">
          <div class="address" *ngIf="route_eCMR.originTenantFormattedAddress">
            {{ route_eCMR.originTenantFormattedAddress }}&nbsp;
            <mat-icon (click)="obrirDireccio(null, route_eCMR.originTenantFormattedAddress)"
              class="material-icons-outlined">location_on</mat-icon>
          </div>
          <div>{{ route_eCMR.originTenantContactName }}</div>
        </div>
        <div class="ecmr-block-col">
          <div class="phone" *ngIf="route_eCMR.originTenantContactPhone">
            <a href="{{ 'tel:' + route_eCMR.originTenantContactPhone }}">
              <mat-icon class="material-icons-outlined">call_outline</mat-icon>
            </a>
            {{ route_eCMR.originTenantContactPhone }}&nbsp;
            <shared-copyclipboard [value]="route_eCMR.originTenantContactPhone"></shared-copyclipboard>
          </div>
        </div>
      </div>
    </div>
    <div class="ecmr-block sub origin">
      <span class="ecmr-block-title">
        {{ 'ECMR.ORIGINADDRESS.TITLE' | translate }}
        <mat-icon *ngIf="(route_eCMR.isOwner || originAddressBlank()) && !readonly" (click)="updateOriginAddress = true"
          class="material-icons-outlined">edit</mat-icon>
        <mat-icon *ngIf="(route_eCMR.isOwner || originAddressBlank()) && !readonly" (click)="resetOriginAddress()"
          class="material-icons-outlined">refresh</mat-icon>
      </span>

      <updateaddress-dialog [expanded]="updateOriginAddress" title="ECMR.ORIGINADDRESS.TITLE"
        [routePartCMRId]="routePartCMRId" [name]="route_eCMR.originAddressName" [key]="route_eCMR.originAddressKey"
        [formattedAddress]="route_eCMR.originFormattedAddress" [contactPhone]="route_eCMR.originContactPhone"
        [isOrigin]="true" (onConfirm)="loadCMR()" (onCancel)="updateOriginAddress=false"></updateaddress-dialog>

      <div class="ecmr-block-content">
        <div class="ecmr-block-row" *ngIf="route_eCMR.originAddressName">
          <div class="ecmr-block-col">
            <div class="key">{{ route_eCMR.originAddressName }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.originAddressName"></shared-copyclipboard>
            </div>
          </div>
        </div>
        <div class="ecmr-block-row">
          <div class="ecmr-block-col">
            <div class="address" *ngIf="route_eCMR.originFormattedAddress">
              {{ route_eCMR.originFormattedAddress }}&nbsp;
              <mat-icon (click)="obrirDireccio(route_eCMR.originAddressId, route_eCMR.originFormattedAddress)"
                class="material-icons-outlined">location_on</mat-icon>
            </div>
          </div>
          <div class="ecmr-block-col" *ngIf="route_eCMR.originContactPhone">
            <div class="phone">
              <a href="{{ 'tel:' + route_eCMR.originContactPhone }}">
                <mat-icon class="material-icons-outlined">call_outline</mat-icon>
              </a>
              {{ route_eCMR.originContactPhone }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.originContactPhone"></shared-copyclipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block applicant">
    <span class="ecmr-block-title">
      {{ 'ECMR.APPLICANT.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || applicantBlank()) && !readonly" (click)="updateApplicant = true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || applicantBlank()) && !readonly" (click)="resetApplicant()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepartner-dialog [expanded]="updateApplicant" title="ECMR.APPLICANT.TITLE" [routePartCMRId]="routePartCMRId"
      partnerType="applicant" [name]="route_eCMR.applicantName" [nif]="route_eCMR.applicantNif"
      [formattedAddress]="route_eCMR.applicantFormattedAddress" [contactName]="route_eCMR.applicantContactName"
      [contactPhone]="route_eCMR.applicantContactPhone" (onConfirm)="loadCMR()"
      (onCancel)="updateApplicant=false"></updatepartner-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.applicantName || route_eCMR.applicantNif">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.applicantName }}
            <shared-copyclipboard [value]="route_eCMR.applicantName"></shared-copyclipboard>
          </div>
        </div>
        <div class="ecmr-block-col">
          <div class="key">{{ route_eCMR.applicantNif }}
            <shared-copyclipboard [value]="route_eCMR.applicantNif"></shared-copyclipboard>
          </div>
        </div>
      </div>
      <div class="ecmr-block-row">
        <div class="ecmr-block-col">
          <div class="address" *ngIf="route_eCMR.applicantFormattedAddress">
            {{ route_eCMR.applicantFormattedAddress }}&nbsp;
            <mat-icon (click)="obrirDireccio(null, route_eCMR.applicantFormattedAddress)"
              class="material-icons-outlined">location_on</mat-icon>
          </div>
          <div>{{ route_eCMR.applicantContactName }}</div>
        </div>
        <div class="ecmr-block-col">
          <div class="phone" *ngIf="route_eCMR.applicantContactPhone">
            <a href="{{ 'tel:' + route_eCMR.applicantContactPhone }}">
              <mat-icon class="material-icons-outlined">call_outline</mat-icon>
            </a>
            {{ route_eCMR.applicantContactPhone }}&nbsp;
            <shared-copyclipboard [value]="route_eCMR.applicantContactPhone"></shared-copyclipboard>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block carrier">
    <span class="ecmr-block-title">
      {{ 'ECMR.CARRIER.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || carrierBlank()) && !readonly" (click)="updateCarrier = true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || carrierBlank()) && !readonly" (click)="resetCarrier()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepartner-dialog [expanded]="updateCarrier" title="ECMR.CARRIER.TITLE" [routePartCMRId]="routePartCMRId"
      partnerType="carrier" [name]="route_eCMR.carrierName" [nif]="route_eCMR.carrierNif"
      [formattedAddress]="route_eCMR.carrierFormattedAddress" [contactName]="route_eCMR.carrierContactName"
      [contactPhone]="route_eCMR.carrierContactPhone" (onConfirm)="loadCMR()"
      (onCancel)="updateCarrier=false"></updatepartner-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.carrierName || route_eCMR.carrierNif">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.carrierName }}
            <shared-copyclipboard [value]="route_eCMR.carrierName"></shared-copyclipboard>
          </div>
        </div>
        <div class="ecmr-block-col">
          <div class="key">{{ route_eCMR.carrierNif }}
            <shared-copyclipboard [value]="route_eCMR.carrierNif"></shared-copyclipboard>
          </div>
        </div>
      </div>
      <div class="ecmr-block-row">
        <div class="ecmr-block-col">
          <div class="address" *ngIf="route_eCMR.carrierFormattedAddress">
            {{ route_eCMR.carrierFormattedAddress }}&nbsp;
            <mat-icon (click)="obrirDireccio(null, route_eCMR.carrierFormattedAddress)"
              class="material-icons-outlined">location_on</mat-icon>
          </div>
          <div>{{ route_eCMR.carrierContactName }}</div>
        </div>
        <div class="ecmr-block-col">
          <div class="phone" *ngIf="route_eCMR.carrierContactPhone">
            <a href="{{ 'tel:' + route_eCMR.carrierContactPhone }}">
              <mat-icon class="material-icons-outlined">call_outline</mat-icon>
            </a>
            {{ route_eCMR.carrierContactPhone }}&nbsp;
            <shared-copyclipboard [value]="route_eCMR.carrierContactPhone"></shared-copyclipboard>
          </div>
        </div>
      </div>
    </div>
    <div class="ecmr-block sub driver" *ngIf="route_eCMR.driverName">
      <span class="ecmr-block-title">
        {{ 'ECMR.DRIVER.TITLE' | translate }}
        <mat-icon *ngIf="(route_eCMR.isOwner || driverBlank()) && !readonly" (click)="updateDriver = true"
          class="material-icons-outlined">edit</mat-icon>
        <mat-icon *ngIf="(route_eCMR.isOwner || driverBlank()) && !readonly" (click)="resetDriver()"
          class="material-icons-outlined">refresh</mat-icon>
      </span>

      <updatepartner-dialog [expanded]="updateDriver" title="ECMR.DRIVER.TITLE" [routePartCMRId]="routePartCMRId"
        partnerType="driver" [name]="route_eCMR.driverName" [nif]="route_eCMR.driverNif"
        [contactPhone]="route_eCMR.driverPhone" (onConfirm)="loadCMR()"
        (onCancel)="updateDriver=false"></updatepartner-dialog>

      <div class="ecmr-block-content">
        <div class="ecmr-block-row">
          <div class="ecmr-block-col">
            <div class="key">{{ route_eCMR.driverName }}</div>
          </div>
          <div class="ecmr-block-col">
            <div class="key">{{ route_eCMR.driverNif }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.driverNif"></shared-copyclipboard>
            </div>
          </div>
        </div>
        <div class="ecmr-block-row">
          <div class="ecmr-block-col">
          </div>
          <div class="ecmr-block-col" *ngIf="route_eCMR.driverPhone">
            <div class="phone">
              <a href="{{ 'tel:' + route_eCMR.driverPhone }}">
                <mat-icon class="material-icons-outlined">call_outline</mat-icon>
              </a>
              {{ route_eCMR.driverPhone }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.driverPhone"></shared-copyclipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block destinatary">
    <span class="ecmr-block-title">
      {{ 'ECMR.RECIPIENT.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || destinationTenantBlank()) && !readonly"
        (click)="updateDestinationTenant = true" class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || destinationTenantBlank()) && !readonly" (click)="resetDestinationTenant()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepartner-dialog [expanded]="updateDestinationTenant" title="ECMR.RECIPIENT.TITLE"
      [routePartCMRId]="routePartCMRId" partnerType="destination" [tenantId]="route_eCMR.destinationTenantId"
      [name]="route_eCMR.destinationTenantName" [nif]="route_eCMR.destinationTenantNif"
      [formattedAddress]="route_eCMR.destinationTenantFormattedAddress"
      [contactName]="route_eCMR.destinationTenantContactName" [contactPhone]="route_eCMR.destinationTenantContactPhone"
      (onConfirm)="loadCMR()" (onCancel)="updateDestinationTenant=false"></updatepartner-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.destinationTenantName || route_eCMR.destinationTenantNif">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.destinationTenantName }}
            <shared-copyclipboard [value]="route_eCMR.destinationTenantName"></shared-copyclipboard>
          </div>
        </div>
        <div class="ecmr-block-col">
          <div class="key">{{ route_eCMR.destinationTenantNif }}
            <shared-copyclipboard [value]="route_eCMR.destinationTenantNif"></shared-copyclipboard>
          </div>
        </div>
      </div>
      <div class="ecmr-block-row">
        <div class="ecmr-block-col">
          <div class="address" *ngIf="route_eCMR.destinationTenantFormattedAddress">
            {{ route_eCMR.destinationTenantFormattedAddress }}&nbsp;
            <mat-icon (click)="obrirDireccio(null, route_eCMR.destinationTenantFormattedAddress)"
              class="material-icons-outlined">location_on</mat-icon>
          </div>
          <div>{{ route_eCMR.destinationTenantContactName }}</div>
        </div>
        <div class="ecmr-block-col">
          <div class="phone" *ngIf="route_eCMR.destinationTenantContactPhone">
            <a href="{{ 'tel:' + route_eCMR.destinationTenantContactPhone }}">
              <mat-icon class="material-icons-outlined">call_outline</mat-icon>
            </a>
            {{ route_eCMR.destinationTenantContactPhone }}&nbsp;
            <shared-copyclipboard [value]="route_eCMR.destinationTenantContactPhone"></shared-copyclipboard>
          </div>
        </div>
      </div>
    </div>
    <div class="ecmr-block sub destination">
      <span class="ecmr-block-title">
        {{ 'ECMR.DESTINATIONADDRESS.TITLE' | translate }}
        <mat-icon *ngIf="(route_eCMR.isOwner || destinationAddressBlank()) && !readonly"
          (click)="updateDestinationAddress = true" class="material-icons-outlined">edit</mat-icon>
        <mat-icon *ngIf="(route_eCMR.isOwner || destinationAddressBlank()) && !readonly"
          (click)="resetDestinationAddress()" class="material-icons-outlined">refresh</mat-icon>
      </span>

      <updateaddress-dialog [expanded]="updateDestinationAddress" title="ECMR.DESTINATIONADDRESS.TITLE"
        [routePartCMRId]="routePartCMRId" [name]="route_eCMR.destinationAddressName"
        [key]="route_eCMR.destinationAddressKey" [formattedAddress]="route_eCMR.destinationFormattedAddress"
        [contactPhone]="route_eCMR.destinationContactPhone" [isDestination]="true" (onConfirm)="loadCMR()"
        (onCancel)="updateDestinationAddress=false"></updateaddress-dialog>

      <div class="ecmr-block-content">
        <div class="ecmr-block-row" *ngIf="route_eCMR.destinationAddressName">
          <div class="ecmr-block-col">
            <div class="key">{{ route_eCMR.destinationAddressName }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.destinationAddressName"></shared-copyclipboard>
            </div>
          </div>
        </div>
        <div class="ecmr-block-row">
          <div class="ecmr-block-col">
            <div class="address" *ngIf="route_eCMR.destinationFormattedAddress">
              {{ route_eCMR.destinationFormattedAddress }}&nbsp;
              <mat-icon (click)="obrirDireccio(route_eCMR.destinationAddressId, route_eCMR.destinationFormattedAddress)"
                class="material-icons-outlined">location_on</mat-icon>
            </div>
          </div>
          <div class="ecmr-block-col" *ngIf="route_eCMR.destinationContactPhone">
            <div class="phone">
              <a href="{{ 'tel:' + route_eCMR.destinationContactPhone }}">
                <mat-icon class="material-icons-outlined">call_outline</mat-icon>
              </a>
              {{ route_eCMR.destinationContactPhone }}&nbsp;
              <shared-copyclipboard [value]="route_eCMR.destinationContactPhone"></shared-copyclipboard>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block deliveryLines">
    <span class="ecmr-block-title">{{ 'ECMR.DELIVERYLINES.TITLE' | translate }}</span>
    <div class="ecmr-block-content" *ngIf="route_eCMR.deliveryLines">
      <div class="ecmr-block-row" *ngFor="let line of route_eCMR.deliveryLines | sortBy:'order'; let i=index">
        <shared-route-cmr-delivery-line class="line compact" [readonly]="true"
          [lineObject]="line"></shared-route-cmr-delivery-line>
      </div>
    </div>
  </div>

  <div class="ecmr-block conditions">
    <span class="ecmr-block-title">{{ 'ECMR.CONDITIONS.TITLE' | translate }}</span>
    <div class="ecmr-block-content" *ngIf="route_eCMR.conditions">
      <div class="ecmr-block-row" *ngFor="let line of route_eCMR.conditions">
        <span class="line-key" *ngIf="line.key">{{ line.key }}</span>
        <span class="line-data" *ngIf="line.data">{{ line.data }}</span>
      </div>
    </div>
  </div>

  <div class="ecmr-block handlingAndTransportation">
    <span class="ecmr-block-title">{{ 'ECMR.HANDLINGANDTRANSPORTATION.TITLE' | translate }}</span>
    <div class="ecmr-block-content" *ngIf="route_eCMR.handlingAndTransportation">
      <div class="ecmr-block-row"
        *ngFor="let line of route_eCMR.handlingAndTransportation | sortBy:'order'; let i=index">
        <span class="line-key" *ngIf="line.key">{{ line.key }}</span>
        <span class="line-data" *ngIf="line.data">{{ line.data }}</span>
      </div>
    </div>
  </div>

  <div class="ecmr-block events">
    <span class="ecmr-block-title">{{ 'ECMR.EVENTS.TITLE' | translate }}</span>
    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.events && route_eCMR.events.length > 0">
        <shared-cmr-events-list class="minimalist" [events]="route_eCMR.events"></shared-cmr-events-list>
      </div>
    </div>
  </div>

  <div class="ecmr-block payer">
    <span class="ecmr-block-title">
      {{ 'ECMR.PAYER.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || payerBlank()) && !readonly" (click)="updatePayer = true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || payerBlank()) && !readonly" (click)="resetPayer()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepartner-dialog [expanded]="updatePayer" title="ECMR.PAYER.TITLE" [routePartCMRId]="routePartCMRId"
      partnerType="payer" [name]="route_eCMR.payerName" [nif]="route_eCMR.payerNif"
      [formattedAddress]="route_eCMR.payerFormattedAddress" [contactName]="route_eCMR.payerContactName"
      [contactPhone]="route_eCMR.payerContactPhone" (onConfirm)="loadCMR()"
      (onCancel)="updatePayer=false"></updatepartner-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.payerName || route_eCMR.payerNif">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.payerName }}
            <shared-copyclipboard [value]="route_eCMR.payerName"></shared-copyclipboard>
          </div>
        </div>
        <div class="ecmr-block-col">
          <div class="key">{{ route_eCMR.payerNif }}
            <shared-copyclipboard [value]="route_eCMR.payerNif"></shared-copyclipboard>
          </div>
        </div>
      </div>
      <div class="ecmr-block-row">
        <div class="ecmr-block-col">
          <div class="address" *ngIf="route_eCMR.payerFormattedAddress">
            {{ route_eCMR.payerFormattedAddress }}&nbsp;
            <mat-icon (click)="obrirDireccio(null, route_eCMR.payerFormattedAddress)"
              class="material-icons-outlined">location_on</mat-icon>
          </div>
          <div>{{ route_eCMR.payerContactName }}</div>
        </div>
        <div class="ecmr-block-col">
          <div class="phone" *ngIf="route_eCMR.payerContactPhone">
            <a href="{{ 'tel:' + route_eCMR.payerContactPhone }}">
              <mat-icon class="material-icons-outlined">call_outline</mat-icon>
            </a>
            {{ route_eCMR.payerContactPhone }}&nbsp;
            <shared-copyclipboard [value]="route_eCMR.payerContactPhone"></shared-copyclipboard>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block payment">
    <span class="ecmr-block-title">
      {{ 'ECMR.PAYMENT.TITLE' | translate }}
      <mat-icon *ngIf="(route_eCMR.isOwner || paymentBlank()) && !readonly" (click)="updatePayment = true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || paymentBlank()) && !readonly" (click)="resetPayment()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>

    <updatepayment-dialog [expanded]="updatePayment" title="ECMR.PAYMENT.TITLE" [routePartCMRId]="routePartCMRId"
      [payment]="route_eCMR.payment" [amount]="route_eCMR.amount" (onConfirm)="loadCMR()"
      (onCancel)="updatePayment=false"></updatepayment-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.payment">
        <div class="ecmr-block-col">
          <div>{{ route_eCMR.payment }}</div>
        </div>
        <div class="ecmr-block-col">
          <div>{{'ROUTEPARTCMR.MAINTENANCE.ADDITIONALDATA.AMOUNT.CAPTION' | translate}}: {{ route_eCMR.amount }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="ecmr-block incoterm">
    <span class="ecmr-block-title">
      {{ 'ECMR.INCOTERM.TITLE' | translate}}
      <mat-icon *ngIf="(route_eCMR.isOwner || incotermBlank()) && !readonly" (click)="incotermExpanded=true"
        class="material-icons-outlined">edit</mat-icon>
      <mat-icon *ngIf="(route_eCMR.isOwner || incotermBlank()) && !readonly" (click)="resetIncoterm()"
        class="material-icons-outlined">refresh</mat-icon>
    </span>
    <updateincoterm-dialog [expanded]="incotermExpanded" title="ECMR.INCOTERM.TITLE" [routePartCMRId]="routePartCMRId"
      [incoterm]="route_eCMR.incoterm" (onConfirm)="loadCMR()"
      (onCancel)="incotermExpanded=false"></updateincoterm-dialog>

    <div class="ecmr-block-content">
      <div class="ecmr-block-row" *ngIf="route_eCMR.incoterm && route_eCMR.incoterm != incotermNone ">
        <div class="ecmr-block-col">
          <div class="name key">{{ route_eCMR.incoterm }}</div>
        </div>
      </div>
    </div>
  </div>


  <div class="ecmr-block signatures" *ngIf="showsignatures">
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.SENDER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'sender'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CARRIER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'carrier'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CONSIGNEE' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'consignee'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.DRIVER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'driver'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="route_eCMR && route_eCMR.CMRAsDocument">
  <shared-document-view-component class="full" *ngIf="documentDetail!=null" [documentDetail]="documentDetail"
    [nominiatures]="true"></shared-document-view-component>


  <div class="ecmr-block signatures" *ngIf="showsignatures">
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.SENDER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'sender'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CARRIER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'carrier'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.CONSIGNEE' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'consignee'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
    <div class="signatura-block">
      <span class="ecmr-block-title">
        {{ 'ROUTEPARTCMRSIGNATUREAGENTS.ENUM.DRIVER' | translate}}
      </span>
      <shared-sign-cmr-maintenance class="nomessignagtura nofixed noborder justsignature"
        [routePartCMRId]="routePartCMRId" [type]="'driver'" [readonly]="true"></shared-sign-cmr-maintenance>
    </div>
  </div>
</ng-container>