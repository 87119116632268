import { Component, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { SocketController } from '@shared/src/controllers/socket/socket.controller';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { AlertService, HString } from '@shared/src/public-api';

@Component({
    selector: 'shared-exwork-route-part-cmr-digitaldocument',
    templateUrl: './shared.exwork.route.part.cmr.digitaldocument.component.html',
    styleUrls: ['./shared.exwork.route.part.cmr.digitaldocument.component.scss']
})
export class SharedExworkRoutePartCMRDigitalDocumentComponent implements OnInit {


    public useIonic: boolean = false;
    constructor(protected socketController: SocketController, public router: Router, protected activatedRoute: ActivatedRoute,
        @Inject('HomeController') protected homeController: HomeController,
        public openRouteCMRController: OpenRouteCMRController, public systemController: SystemController,
        @Inject('AlertService') public alertService: AlertService) {


        if (homeController.useIonic)
            this.useIonic = homeController.useIonic();
    }

    ngOnInit(): void {
        this.getDades();
    }

    public uuid: string = "";
    public getDades() {
        this.activatedRoute.params.subscribe(a => {
            this.uuid = a.uuidQR;
            this.getRoutePartCMRIdByQR();
        });
    }

    public __showSignature: boolean = true;
    @Input()
    public set showSignature(value: boolean) {
        if (this.__showSignature === value)
            return;
        this.__showSignature = value;
    }
    public get showSignature(): boolean {
        return this.__showSignature;
    }


    public routePartCMRId: number;
    getRoutePartCMRIdByQR() {

        if (HString.isNullOrNullString(this.uuid)) {
            this.routePartCMRId = 0;
        } else {
            this.openRouteCMRController.getRoutePartCMRByUUID(this.uuid).subscribe(d => {
                this.routePartCMRId = d;
            });
        }
    }


    public pretty(value: any): string {
        return JSON.stringify(value);
    }

}
