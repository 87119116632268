<div *ngIf="route" [ngClass]="{'ionic' : useIonic}">
    <div class="column route maxwidth">
        <div class="title" *ngIf="route">
            <div class="buttonBack" (click)="onClickBack($event)">
                <i class="fa fa-arrow-left" aria-hidden="true"></i>
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            {{'SHARED.ROUTE.INFO.COMPOMENT.ROUTE.TITLE' | translate}}
            <button *ngIf="!useIonic" class="general-link-boto jump" (click)="goToActiveSlots()"><i class="fa fa-share"
                    aria-hidden="true"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AS.PROMPT' |
                translate}}</button>
        </div>
        <shared-route-item-maintenance [route]="route" [selectedPhase]="selectedPhase"
            [selectedShipment]="selectedShipment" [selectedRoutePartCMR]="selectedRoutePartCMR"
            [(selectedOption)]="selectedOption" [readonly]="readonly" (selectedPhaseChange)="onSelectedPhase($event)"
            (selectedShipmentChange)="onSelectedShipment($event)"
            (selectedRoutePartCMRChange)="onSelectedRoutePartCMR($event)"
            (showDocumentaryRevisionChange)="onShowDocumentaryRevisionChange($event)"
            (routeUpdated)="onRouteUpdated($event)" (phaseCoordsChange)="onRefreshRoute($event)"
            [fromMaintenance]="fromMaintenance" [fromActiveRoutes]="fromActiveRoutes">
        </shared-route-item-maintenance>
    </div>
    <div class="column phase maxwidth" *ngIf="selectedPhase">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.PHASE.TITLE' | translate}}
        </div>
        <shared-phase-item-maintenance [phase]="selectedPhase" [route]="route" [readonly]="readonly"
            [selectedShipment]="selectedShipment" (phaseUpdated)="onPhaseUpdated($event)"
            (refreshPhase)="onRefreshPhase($event)" (refreshRoute)="loadRouteData()"
            (selectedShipmentChange)="onSelectedShipment($event)">
        </shared-phase-item-maintenance>
    </div>
    <div class="column shipment maxwidth" *ngIf="selectedPhase && selectedShipment">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.SHIPMENT.TITLE' | translate}}
        </div>
        <shared-shipment-item-maintenance [shipment]="selectedShipment" [phase]="selectedPhase" [routeId]="routeId"
            [readonly]="readonly" (shipmentUpdated)="onShipmentUpdated($event)"
            (refreshShipment)="onRefreshShipment($event)">
        </shared-shipment-item-maintenance>
    </div>
    <div class="wide-column shipment maxwidth" *ngIf="selectedShipment && !selectedPhase">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.SHIPMENT.TITLE' | translate}}
        </div>
        <shared-shipment-item-maintenance [shipment]="selectedShipment" [routeId]="routeId" [readonly]="readonly"
            (shipmentUpdated)="onShipmentUpdated($event)" (refreshShipment)="onRefreshShipment($event)">
        </shared-shipment-item-maintenance>
    </div>
    <div class="wide-column shipment maxwidth" *ngIf="selectedRoutePartCMR">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.CMR.TITLE' | translate}}
        </div>
        <shared-route-part-cmr-maintenance [routePartCMR]="selectedRoutePartCMR" [readonly]="readonly"
            (routePartCMRUpdated)="onRoutePartCMRUpdated($event)" (refreshRoutePartCMR)="onRefreshRoutePartCMR($event)"
            (refreshRoute)="loadRouteData()">
        </shared-route-part-cmr-maintenance>
    </div>

    <div class="wide-column shipment maxwidth" *ngIf="showDocumentaryRevision">
        <div class="title">
            {{'SHARED.ROUTE.INFO.COMPOMENT.DOCUMENTREVIEW.TITLE' | translate}}
        </div>
        <shared-documentaryreview-route-item [routeId]="routeId" [readonly]="readonly">
        </shared-documentaryreview-route-item>

    </div>
    <div class="wide-column shipment maxwidth" *ngIf="selectedOption == 'TELEMETRY'">
        <shared-telemetry class="maintenance-component fullheight reduced" [model]="'Route'" [modelId]="routeId"
            [dataInici]="route.dateHourFrom" [dataFi]="route.dateHourTo" [isActiveRoutes]="true"></shared-telemetry>
    </div>
</div>