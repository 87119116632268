<div class="container" *ngIf="value">
    <div class="line titol" *ngIf="showtitle">
        {{ 'SHARED.ROUTE.GROUP.TITLE' | translate }}
    </div>
    <div class="linia  baroptions">
        <options class="slim" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
    </div>
    <ng-container *ngIf="actualOptionId=='SHIPMENTS' && value.shipments && value.shipments.length > 0">
        <div class="shipment-item"
            *ngFor="let shipment of value.shipments | sortBy:'originAddressSkin,partnerClientTradeMark,shipmentKey'; let i=index">
            <div class="address-header"
                *ngIf="(i == 0 || shipment.originAddressSkin != value.shipments[i-1].originAddressSkin)">
                {{ shipment.originAddressSkin }}
            </div>
            <div class="client-header"
                *ngIf="(i == 0 || shipment.partnerClientId != value.shipments[i-1].partnerClientId)"
                (dblclick)="copy(shipment.partnerClientTradeMark)">
                <span *ngIf="shipment.partnerClientId">{{ shipment.partnerOrganizationName }} ({{
                    shipment.partnerClientKey }})</span>
            </div>
            <div class="line"><span class="value key" (dblclick)="copy(shipment.shipmentKey)">{{ shipment.shipmentKey
                    }} <shared-copyclipboard [value]="shipment.shipmentKey"></shared-copyclipboard> </span> <span
                    class="description">{{ shipment.shipmentDescription }}</span></div>
            <div class="line description goods">{{ shipment.goods }}</div>
        </div>
    </ng-container>

    <ng-container *ngIf="actualOptionId=='ROUTES' && value.routes && value.routes.length > 0">
        <div class="route-item "
            *ngFor="let route of value.routes | sortBy:'originAddressSkins,partnerClientsTradeMark,routeKey'; let i=index">
            <div class="address-header"
                *ngIf="(i == 0 || route.originAddressSkins != value.routes[i-1].originAddressSkins)">
                {{ route.originAddressSkins }}
            </div>
            <div class="client-header"
                *ngIf="(i == 0 || route.partnerClientsTradeMark != value.routes[i-1].partnerClientsTradeMark)">
                <span *ngIf="route.partnerClientsTradeMark">{{ route.partnerClientsTradeMark }}</span>
            </div>
            <div class="line"><span class="value key" (dblclick)="copy(route.routeKey)">{{ route.routeKey
                    }}<shared-copyclipboard [value]="route.routeKey"></shared-copyclipboard></span>
                <div class="panel-right">
                    <button class="general-link-boto jump light" (click)="onARClick(route)"><i aria-hidden="true"
                            class="fa fa-share"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AR.PROMPT'
                        |
                        translate}}</button>
                    <button class="general-link-boto jump light" (click)="onASClick(route)"><i aria-hidden="true"
                            class="fa fa-share"></i>{{'SHARED.ACTIVE.ROUTE.PHASE.COMPONENT.OPTION.AS.PROMPT'
                        |
                        translate}}</button>
                </div>
            </div>
            <div class="line description goods">{{ route.goods }}
            </div>

        </div>
    </ng-container>
</div>