import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, Subscription, throwError as observableThrowError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { api } from '../environments/environment';
import { AlertService } from '../services/alert/alert.service';
import { LoadingService } from '../services/loading/loading.service';
import { LogController } from './log/LogController';
import { ResponseData } from './ResponseData';

type SWITCH_MAP_EVENT_TYPE = { subject: Subject<REQUEST_CONFIG>, observable?: Observable<any> };
type REQUEST_EVENT = { topic: string, observable: Observable<any>, config: REQUEST_CONFIG };
type REQUEST_CONFIG = { url: string, body?: any, method?: 'GET' | 'GETBUSY' | 'POST' | 'POSTBUSY' | 'PUT' | 'PUTBUSY' | 'DELETE' | 'DELETEBUSY' };

@Injectable()
export class BaseController {

    private mapaRequests = new Map<String, number>();

    constructor(protected http: HttpClient,
        @Inject('AlertService') protected alertService: AlertService,
        protected translateService: TranslateService,
        @Inject('LoadingService') protected loadingService: LoadingService,
        protected router: Router) {
    }

    protected internal_put<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        let transactionId = this.createTransactionId(url, ptransactionId);
        let transactionNum = this.reserveTransactionNum(transactionId);
        let options = { headers: this.getHeader(transactionId, transactionNum) };
        return this.extractResponse(this.http.put<ResponseData<T>>(api(url), body, options), this.router, this.alertService, transactionId, transactionNum, this.loadingService);
    }
    protected internal_putBusy<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        this.loadingService.showBusy();
        return this.put(url, body, ptransactionId);
    }

    protected internal_post<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        let transactionId = this.createTransactionId(url, ptransactionId);
        let transactionNum = this.reserveTransactionNum(transactionId);
        let options = { headers: this.getHeader(transactionId, transactionNum) };
        return this.extractResponse(this.http.post<ResponseData<T>>(api(url), body, options), this.router, this.alertService, transactionId, transactionNum, this.loadingService);
    }

    protected internal_postBusy<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        this.loadingService.showBusy();
        return this.internal_post(url, body, ptransactionId);
    }
    protected internal_get<T>(url: string): Observable<T> {
        let transactionId = url;
        let transactionNum = this.reserveTransactionNum(transactionId);
        let options = { headers: this.getHeader(transactionId, transactionNum) };
        return this.extractResponse(this.http.get<ResponseData<T>>(api(url), options), this.router, this.alertService, transactionId, transactionNum, this.loadingService);
    }
    protected internal_getBusy<T>(url: string): Observable<T> {
        this.loadingService.showBusy();
        return this.get(url);
    }
    protected internal_delete<T>(url: string): Observable<T> {
        let transactionId = url;
        let transactionNum = this.reserveTransactionNum(transactionId);
        let options = { headers: this.getHeader(transactionId, transactionNum) };
        return this.extractResponse(this.http.delete<ResponseData<T>>(api(url), options), this.router, this.alertService, transactionId, transactionNum, this.loadingService);
    }
    protected internal_deleteBusy<T>(url: string): Observable<T> {
        this.loadingService.showBusy();
        return this.delete(url);
    }

    protected extractResponse<T>(respostaBack: Observable<ResponseData<T>>, router: Router, alertService: AlertService, transactionId: string, transactionNum: string, loadingService: LoadingService): Observable<T> {

        if (this.isActiveTransactionNum(transactionId, transactionNum))
            return respostaBack.pipe(
                map((res: ResponseData<T>) =>
                    BaseController.extractData(res, alertService, loadingService, router)),
                catchError((err: HttpErrorResponse) =>
                    BaseController.handleErrorObservable<T>(err, router, alertService, loadingService)));
        else {
            return new Observable<T>(obs => {
                obs.complete();
            })
        }
    }

    /**
    * Extreu la informació del responsedata si es undefined retorna {}
    */
    private static extractData<T>(res: ResponseData<T>, alertService: AlertService, loadingService: LoadingService, router: Router): T {
        if (loadingService != null)
            loadingService.hideBusy();

        let data = this.extractDataUndefined(res, alertService);

        if (res.isException) {
            //not authorized fem logout
            if (ResponseData.exist401(res.responseMessages)) {
                console.log('extractData /logout');
                router.navigateByUrl("/logout");
            }

        }

        if (data == undefined)
            return {} as T;

        try {
            let result = data as T;
            return result;
        }
        catch {
            return {} as T;
        }
    }

    /**
     * Extreu la informació del responsedata si es undefined retorna undefined
     */
    private static extractDataUndefined<T>(res: ResponseData<T>, alertService: AlertService): T {
        if (alertService && alertService.showServerMessage)
            alertService.showServerMessage(res, true);
        if (res == null)
            return undefined;
        return res.data;
    }

    /**
     * Tractament dels errors que rebem de back.
     */
    private static handleErrorObservable<T>(error: HttpErrorResponse, router: Router, alertService: AlertService, loadingService: LoadingService): Observable<T> {

        if (loadingService != null)
            loadingService.clearBusy();

        if (LogController.LOGGED && (error.status == 401 || error.status == 403)) {
            console.log('handleError /logout');
            router.navigateByUrl("/logout");

            //Aquí podem comprobar aquest error a que es deu i treure'l fora de l'aplicació. En realiat un 500 és un usuari desconegut?
            //Haig de mirar que passa si es posa una opció que no existeix, dona el mateix?
        }

        // Tractem l'error de forma especial sempre i quan compleixi condicions
        if (error && error.error) {
            if (error.error.data != undefined && error.error.responseMessages != undefined) {
                this.extractDataUndefined(error.error, alertService);
                return observableThrowError(error);
            }
        }
        if (alertService && alertService.showError)
            alertService.showError(error, true);

        return observableThrowError(error);
    }

    private isActiveTransactionNum(transactionId: string, transactionNum: string): boolean {
        if (!this.mapaRequests.has(transactionId))
            return true;
        let activeTransactionNum = this.mapaRequests.get(transactionId).toString();
        if (activeTransactionNum === transactionNum) {
            this.mapaRequests.delete(transactionId);
            return true;
        }
        return false;
    }

    protected createTransactionId(url: string, transactionId: string) {
        let fullTransactionId = url + "#" + transactionId;
        return fullTransactionId;
    }
    protected reserveTransactionNum(url: string): string {
        let transactionNum = this.mapaRequests.get(url);
        if (transactionNum == null)
            transactionNum = 0;

        transactionNum++;
        this.mapaRequests.set(url, transactionNum);
        return transactionNum.toString();
    }
    /***
     * REtorna el header a on s'incorpora el transId. El transId ens permet emetre o parar transaccions quan n'hi ha d'altres de pendents de processar
     */
    protected getHeader(transactionId: string, transactionNum: string): any {
        //let headers = new HttpHeaders({ 'Content-Type': 'application/json', 'transactionId': transactionId, 'transactionNum': transactionNum });
        let headers = { 'Content-Type': 'application/json', 'transactionId': transactionId, 'transactionNum': transactionNum };
        return headers;
    }




    ///////////////// SWITCH MAP LAB ////////////////////
    protected mapSubscriptionByKey: Map<string, Subscription> = new Map<string, Subscription>();
    protected mapEventsByRequest: Map<string, SWITCH_MAP_EVENT_TYPE> = new Map<string, SWITCH_MAP_EVENT_TYPE>();

    /**
     * El switchMap funciona de la següent forma: tu et subscrius a un event en concret, aquest event quan salta 
     * ha de fer certes coses per lo que genera un Observable fins que ha acabat, si durant el temps que 
     * l'Observable està fent coses l'event torna a saltar, s'anul·la tot el que havia fet l'Observable i en 
     * comença un de nou.
     * 
     * Això ho podríem traduir amb un cas fàcil: rutes actives. Si fem una crida a rutes actives, mentre estigui 
     * fent la crida nosaltres podem modificar els filtres igualment, això provoca una nova crida. Sense el switchMap 
     * hauríem fet les 2 senceres i "renderitzat" els resultats dos cops. Amb el switchMap anul·lem la primera crida 
     * si encara no ha acabat i comencem la segona, estalviant-nos veure pampallugues per pantalla.
     * 
     * @param key és l'identificador de l'event. Per ara utilitzem `mètode HTTP`:`URI`
     * @returns 
     */
    protected getEvent(key: string): SWITCH_MAP_EVENT_TYPE {
        if (!this.mapEventsByRequest.has(key)) {
            const subject: Subject<REQUEST_CONFIG> = new Subject<REQUEST_CONFIG>();
            this.mapEventsByRequest.set(key, {
                subject: subject
            });

            const object: SWITCH_MAP_EVENT_TYPE = this.mapEventsByRequest.get(key);

            object.observable = object.subject.pipe(
                switchMap((val) => {
                    switch (val.method) {
                        case 'GET':
                            return this.internal_get(val.url);
                        case 'GETBUSY':
                            return this.internal_getBusy(val.url);
                        case 'POST':
                            return this.internal_post(val.url, val.body, key);
                        case 'POSTBUSY':
                            return this.internal_postBusy(val.url, val.body, key);
                        case 'PUT':
                            return this.internal_put(val.url, val.body, key);
                        case 'PUTBUSY':
                            return this.internal_putBusy(val.url, val.body, key);
                        case 'DELETE':
                            return this.internal_delete(val.url);
                        case 'DELETEBUSY':
                            return this.internal_deleteBusy(val.url);
                        default:
                            throw new Error("Invalid HTTP method:" + val.method);
                    }
                })
            )
        }


        return this.mapEventsByRequest.get(key);
    }

    protected request_SwitchMap<T>(requestConfig: REQUEST_CONFIG): Observable<T> {
        return new Observable<T>(observer => {
            // Generem l'identificador de la crida
            const key = requestConfig.method + ':' + requestConfig.url;

            // Obtenim l'event
            const object: SWITCH_MAP_EVENT_TYPE = this.getEvent(key);

            // Si  ja estàvem subscrits ens carreguem la subscripció 
            if (this.mapSubscriptionByKey.has(key) && !this.mapSubscriptionByKey.get(key).closed) {
                if (requestConfig.method.includes("BUSY"))
                    this.loadingService.hideBusy();
                this.mapSubscriptionByKey.get(key).unsubscribe();
            }

            // Ens subscrivim amb l'única funció de retornar un observable amb la crida ja feta
            const subscription = object.observable.subscribe(
                data => {
                    observer.next(data);
                },
                error => {
                    console.log("switchMap", error)
                    this.loadingService.clearBusy();
                });

            // Ens guardem la subscripció
            this.mapSubscriptionByKey.set(key, subscription);

            // Avisem al subject per a que faci una crida
            object.subject.next(requestConfig);
        });
    }


    protected get<T>(url: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, method: 'GET' });
    }
    protected getNoCancel<T>(url: string): Observable<T> {
        return this.internal_get(url);
    }

    protected getBusy<T>(url: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, method: 'GETBUSY' });
    }

    protected post<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, body: body, method: 'POST' });
    }

    protected postBusyNoCancel<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        return this.internal_postBusy(url, body, ptransactionId);
    }
    protected postBusy<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, body: body, method: 'POSTBUSY' });
    }

    protected put<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, body: body, method: 'PUT' });
    }

    protected putBusy<T>(url: string, body: any, ptransactionId: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, body: body, method: 'PUTBUSY' });
    }

    protected delete<T>(url: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, method: 'DELETE' });
    }

    protected deleteBusy<T>(url: string): Observable<T> {
        return this.request_SwitchMap<T>({ url: url, method: 'DELETEBUSY' });
    }

    public encode(searchText: string): string {
        if (searchText == null || searchText == "")
            return "";
        return searchText.replace("/", "~");
    }

}