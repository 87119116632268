import { Component, Input, Inject, Output, EventEmitter } from '@angular/core';
import { DocumentController } from '@shared/src/controllers/document/document.controller';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { RoutePartCMRBaseDto } from '@shared/src/dtos/route/cmr/RoutePartCMRBaseDto';
import { RoutePartCMRTypes } from '@shared/src/enums/RoutePartCMRTypes';

@Component({
  selector: 'shared-documentaryreview-item-ecmr-component',
  templateUrl: './shared.documentaryreview.item.ecmr.component.html',
  styleUrls: ['./shared.documentaryreview.item.ecmr.component.scss'],
})
export class SharedDocumentaryReviewItemECMRComponent {

  public useIonic: boolean = false;

  constructor(@Inject('HomeController') public homeController: HomeController,
  public documentController: DocumentController) {

    if (homeController.useIonic)
      this.useIonic = homeController.useIonic();
  }
  
  @Input() public readonly: boolean = false;

  public __value: RoutePartCMRBaseDto;
  @Input()
  set value(value: RoutePartCMRBaseDto) {
    if (this.__value === value)
      return;
    this.__value = value;
    this.setResourceTitle();
  }
  get value(): RoutePartCMRBaseDto {
    return this.__value;
  }

  @Output() dataRefresh: EventEmitter<boolean> = new EventEmitter();
  public onDataRefresh(value: boolean) {
    this.dataRefresh.next(value);
  }

  ngOnInit() {

  }

  public resourceTitle = 'DOCUMENTARYREVIEW.ECMR.CMR';
  setResourceTitle(){
    if(this.value == null)
      return;
    if(this.value.routePartCMRType == RoutePartCMRTypes.CMR)
      this.resourceTitle = 'DOCUMENTARYREVIEW.ECMR.CMR';
    else if(this.value.routePartCMRType == RoutePartCMRTypes.PortChart)
      this.resourceTitle = 'DOCUMENTARYREVIEW.ECMR.PORTCHART';
  }
}
