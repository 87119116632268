import { iPartnerDto } from "./list/iPartnerDto";

export class PartnerInfoDto {
    public title: string;
    public subTitle: string;
    public email: string;
    public phone: string;
    public contactName: string;
    public otherInfo: string;

    public static build(title: string, subTitle: string, email: string, phone: string, contactName: string, otherInfo: string): PartnerInfoDto {

        let dto = new PartnerInfoDto();
        dto.title = title;
        dto.subTitle = subTitle;
        dto.email = email;
        dto.phone = phone;
        dto.contactName = contactName;
        dto.otherInfo = otherInfo;
        return dto;
    }

    static buildFromPartner(d: iPartnerDto): PartnerInfoDto {
        return PartnerInfoDto.build(d.title, d.description, "", "", "", "");
    }


}
