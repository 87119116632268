<div class="maintenance" [ngClass]="generalClass">
    <div class="maintenance-internal">
        <div *ngIf="loadingData" class="maintanance-zone nodata-zone loading">
            loading
        </div>
        <div *ngIf="!loadingData" class="maintenance-zone data-zone">
            <div class="baractions">
                <options class="show-label" [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
                <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
            </div>
            <ng-container *ngIf="option=='ROUTE'">
                <entity-selector class="col-x" [(ngModel)]="deviceId" [entitySelector]="'Device'"
                    (valuechangefunction)="deviceRouteChanged($event)"
                    placeholder="{{'DEVICE.DEVICEID.CAPTION' | translate}}">
                </entity-selector>
            </ng-container>
            <ng-container *ngIf="option=='TELEMETRY'">
                <div class="info-item-body expansion">
                    <div class="info-item-data">
                        <div (click)="onClick()">
                            <div class="title-info-item">
                                <span class="title-text">
                                    <span class="valor"> {{ dateStart | datehouronlydatenotz }} {{ dateStart |
                                        datehouronlyhournotz }} </span>
                                    <span *ngIf="dateStart && dateEnd"> - </span>
                                    <span class="valor"> {{ dateEnd | datehouronlydatenotz }} {{ dateEnd |
                                        datehouronlyhournotz
                                        }} </span>
                                </span>

                                <shared-filter class="readonly"
                                    [ngClass]="{'chip-filter' : activeProviderFilterStrip.list.length > 0}"
                                    *ngIf="filter" [filterstrip]="activeProviderFilterStrip" [applyColor]="true">
                                </shared-filter>
                                <shared-filter class="readonly"
                                    [ngClass]="{'chip-filter' : activeDeviceFilterStrip.list.length > 0}" *ngIf="filter"
                                    [filterstrip]="activeDeviceFilterStrip" [applyColor]="true">
                                </shared-filter>
                                <shared-filter class="readonly"
                                    [ngClass]="{'chip-filter' : activeFilterStrip.list.length > 0}" *ngIf="filter"
                                    [filterstrip]="activeFilterStrip">
                                </shared-filter>

                                <div class="chevron">
                                    <i [ngClass]="isExpanded ? 'fa fa-chevron-down' : 'fa fa-chevron-right'"
                                        aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="isExpanded" class="expanded">
                            <div class="period">
                                <datehour-picker [(ngModel)]="dateStart"
                                    placeholder="{{'ROUTE.DATESTART.CAPTION' | translate}}" name="dateStart"
                                    [showLocaleObserver]=false>
                                </datehour-picker>
                                <datehour-picker [(ngModel)]="dateEnd"
                                    placeholder="{{'ROUTE.DATEEND.CAPTION' | translate}}" name="dateEnd"
                                    [showLocaleObserver]=false [min]="dateStart" [(from)]="dateStart">
                                </datehour-picker>
                            </div>
                            <div class="providers">
                                <shared-filter *ngIf="providersFilterStrip" [filterstrip]="providersFilterStrip"
                                    [applyColor]="true" (change)="providersFilterUpdate($event)">
                                </shared-filter>
                            </div>
                            <div class="devices">
                                <shared-filter *ngIf="devicesFilterStrip" [filterstrip]="devicesFilterStrip"
                                    [applyColor]="true" (change)="devicesFilterUpdate($event)">
                                </shared-filter>
                            </div>
                            <div class="dimensions">
                                <shared-filter *ngIf="filter" [filterstrip]="filter.filterStrip"
                                    (change)="filterUpdate($event)">
                                </shared-filter>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="body">
                    <div class="datos" *ngIf="data != null">
                        <shared-telemetry-table *ngIf="!isActiveRoutes" class="collapsable table"
                            [ngClass]="{'collapsed':tableCollapsed, 'mapCollapsed': mapCollapsed}"
                            [resultType]="data.resultType" [dimensionName]="data.dimensionName"
                            [listTelemetry]="data.listTelemetry" [listDimension]="data.listDimension"
                            [selectedTelemetry]="selectedTelemetry" (markerUpdate)="onTableClick($event)">
                        </shared-telemetry-table>
                        <shared-telemetry-table *ngIf="isActiveRoutes" class="collapsable table reduced"
                            [ngClass]="{'collapsed':tableCollapsed, 'mapCollapsed': mapCollapsed}"
                            [resultType]="data.resultType" [dimensionName]="data.dimensionName"
                            [listTelemetry]="data.listTelemetry" [listDimension]="data.listDimension"
                            [selectedTelemetry]="selectedTelemetry" (markerUpdate)="onTableClick($event)">
                        </shared-telemetry-table>
                        <div class="collapsable-buttonp" [ngClass]="{'collapsed': tableCollapsed || mapCollapsed}">
                            <button *ngIf="!mapCollapsed" class="collapsable-button left"
                                [ngClass]="{'collapsed':tableCollapsed}" (click)="onCollapsableLeftClick()">
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                            </button>
                            <button *ngIf="!tableCollapsed" class="collapsable-button right"
                                [ngClass]="{'collapsed':mapCollapsed}" (click)="onCollapsableRightClick()">
                                <i class="fa fa-chevron-right" aria-hidden="true"></i>
                                <i class="fa fa-chevron-left" aria-hidden="true"></i>
                            </button>
                        </div>
                        <div class="collapsable restOfcollapsable"
                            [ngClass]="{'collapsed':mapCollapsed, 'tableCollapsed': tableCollapsed}">
                            <shared-chart-component class="showtitle" *ngFor="let chart of positionChart"
                                [value]="chart" (ChartClick)="onChartClick($event)" [showSelectedLabel]="true"
                                [selectedIndex]="selectedIndex">
                            </shared-chart-component>
                            <shared-map [marcadors]="marcadors" [rutes]="rutes" [minZoom]="5.5" [forcedZoom]="5.5"
                                (marcadorClick)="onMapClick($event)">
                            </shared-map>
                        </div>
                    </div>
                    <div *ngIf="!haveData" class="nodata-zone loading">
                        no data
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="dummy-maintenance-internal"></div>
</div>