import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { SharedInactivityService } from '@shared/src/services/inactivity/SharedInactivityService';
import { SystemController } from '@shared/src/controllers/system/system.controller';
import { TranslateService } from '@ngx-translate/core';
import { HString } from '@shared/src/public-api';
import { RoutePartCMRSignatureAgents } from '@shared/src/controllers/route/cmr/RoutePartCMRSignatureAgents';
import { UUIDSignatureDto } from '@shared/src/controllers/route/cmr/UUIDSignatureDto';
import { OpenRouteCMRController } from '@shared/src/controllers/open/openroutecmr.controller';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';

@Component({
    selector: 'shared-sign',
    templateUrl: './shared.sign.component.html',
    styleUrls: ['./shared.sign.component.scss']
})
export class SharedSignComponent implements OnInit {

    constructor(protected systemController: SystemController,
        protected activatedRoute: ActivatedRoute,
        private translateService: TranslateService,
        protected inactivityService: SharedInactivityService,
        protected openRouteCMRController: OpenRouteCMRController) {
    }

    public traduccio: string = "";
    public value: UUIDSignatureDto;

    @Output() signedChanged = new EventEmitter<boolean>();

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(queryParams => {
            this.callResolveParams();
        });
        this.activatedRoute.params.subscribe(routeParams => {
            this.callResolveParams();
        });

        this.callResolveParams();
    }

    private _resolvingParams: boolean = false;
    private callResolveParams() {
        if (this._resolvingParams)
            return;
        this._resolvingParams = true;
        this.resolveParams();
        this._resolvingParams = false;
    }

    public __uuid: string;
    @Input()
    set uuid(value: string) {
        if (this.__uuid === value)
            return;
        this.__uuid = value;
        this.loadData();
    }
    get uuid(): string {
        return this.__uuid;
    }

    public __agent: RoutePartCMRSignatureAgents = null;
    @Input()
    set agent(value: RoutePartCMRSignatureAgents) {
        if (this.__agent === value)
            return;
        this.__agent = value;
        this.loadData();
    }
    get agent(): RoutePartCMRSignatureAgents {
        return this.__agent;
    }


    private resolveParams(): boolean {
        let result: boolean = false;
        const routeParams = this.activatedRoute.snapshot.paramMap;
        const queryParams = this.activatedRoute.snapshot.queryParamMap;
        const routeUrl = this.activatedRoute.snapshot.url;

        if (routeParams.get('uuid'))
            this.__uuid = routeParams.get('uuid');
        if (routeParams.get('agent')) {
            let agentParam = routeParams.get('agent') as keyof typeof RoutePartCMRSignatureAgents;
            this.__agent = RoutePartCMRSignatureAgents[agentParam] || RoutePartCMRSignatureAgents.none;
        }
        this.loadData();

        return result;
    }
    loadData() {
        if (!HString.isNullOrNullString(this.uuid) && this.agent != null) {
            this.openRouteCMRController.getSignByAgentAndUUID(this.agent, this.uuid).subscribe(d => {
                this.value = d;
                this.wellcome(this.value);

            });
        }
        else {
            this.value = null;
            this.wellcome(this.value);
        }
    }
    public options: OptionsStripDto = new OptionsStripDto();
    public wellcome(value: UUIDSignatureDto) {

        this.options = new OptionsStripDto();
        if (value == null)
            return;

        let visualitzarMenuBarra = 1;
        if (this.value.partList.length > 3)
            visualitzarMenuBarra = 0;
        this.options.add(OptionDto.build("FIRMA", "FIRMA", "FIRMA", "fa-pencil", 0, 0, visualitzarMenuBarra, true, false));
        let i = 1;
        this.value.partList.forEach(x => {
            this.openRouteCMRController.getRoute_eCMR(x.routePartCMRId).subscribe(y => {
                if (y && y.routePartCMRId) {
                    if (y.CMRAsDocument)
                        this.openRouteCMRController.getLastDocumentDetailsCMR(x.routePartCMRId).subscribe(e => {
                            e.forEach(y => {
                                this.options.add(OptionDto.build(x.routePartCMRId + "." + y.documentDetailId, i.toString(), i.toString(), "fa-file-text", i, 0, visualitzarMenuBarra, false, false));
                                i++;
                            });
                        });
                    else {
                        this.options.add(OptionDto.build(x.routePartCMRId + ".", i.toString(), i.toString(), "fa-file-text", i, 0, visualitzarMenuBarra, false, false));
                        i++;
                    }
                }
            });
        });
        this.onClickOption(this.options.getActualSelected());

    }
    @Input()
    public __option: OptionDto = null;
    public set option(value: OptionDto) {
        if (this.__option != null && value != null && this.__option.optionId == value.optionId)
            return;
        if (value === this.__option)
            return;
        this.__option = value;
    }
    public get option(): OptionDto {
        return this.__option;
    }
    public onClickOption(option: OptionDto) {
        this.option = option;
    }
    getRoutePartCMRID(): number {
        if (this.option == null || this.option.optionId == "0")
            return -1;
        if (this.option.optionId == "FIRMA")
            return -1;

        return this.getFirstNumber(this.option.optionId);
    }

    getDocumentDetailId(): number {
        if (this.option == null || this.option.optionId == "0")
            return -1;
        if (this.option.optionId == "FIRMA")
            return -1;

        return this.getSecondNumber(this.option.optionId);
    }

    public getFirstNumber(input: string): number {
        return parseInt(input.split('.')[0], 10);
    }

    public getSecondNumber(input: string): number {
        return parseInt(input.split('.')[1], 10);
    }



    getOptionId(): number {
        if (this.option != null) {
            if (this.option.optionId == "FIRMA")
                return -1;
            return Number(this.option.optionId);
        }
        return 0;
    }

    onSignedChanged() {
        this.signedChanged.next();
    }

    pretty(value: any): string {
        return JSON.stringify(value);
    }
}


