import { HDate } from '@shared/src/datatypes/HDate';
import { HString } from '@shared/src/datatypes/HString';
import { HHourInterval } from '@shared/src/datatypes/HHourInterval';
import { PhaseSlotLogTypes, RouteCardDto } from '@shared/src/public-api';

export class AssignedSlotDto {

    static toToolTipTextString(list: AssignedSlotDto[]): string {
        let result = "";
        let primer = true;
        list.forEach(p => {
            if (!primer)
                result += ", ";
            result += HString.concat(result, "", HDate.toGetString(p.day));
            result += HString.concat(result, " ", HHourInterval.toScreenString(p.interval));
            if (p.additionalInformation)
                result += HString.concat(result, " additionalInfo:", p.additionalInformation);
            primer = false;
        });

        return result;
    }
    static getAdditionalInformation(list: AssignedSlotDto[]): string {
        if (list == null)
            return "";
        for (let i = 0; i < list.length; i++)
            if (list[i].additionalInformation)
                return list[i].additionalInformation;
        return "";
    }
    static toHourIntervalsString(slot: AssignedSlotDto): string {
        return HHourInterval.toScreenString(slot.interval)
    }
    static toDateString(slot: AssignedSlotDto): String {
        return HDate.getDateString(slot.day);
    }

    static getDate(r: RouteCardDto): HDate {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].day;
        HDate.nullDate();
    }
    static getHHourInterval(r: RouteCardDto): HHourInterval {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].interval;
        return HHourInterval.nullInterval();
    }
    static getHHourIntervalDock(r: RouteCardDto): HHourInterval {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].intervalDock;
        return HHourInterval.nullInterval();
    }
    static getHHourIntervalLift(r: RouteCardDto): HHourInterval {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].intervalLift;
        return HHourInterval.nullInterval();
    }
    static getSlotId(r: RouteCardDto): number {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].slotId;
        return 0;
    }
    static getSlotStatus(r: RouteCardDto): PhaseSlotLogTypes {
        if (r != null)
            if (r.assignedSlots != null)
                if (r.assignedSlots.length != undefined && r.assignedSlots.length > 0)
                    return r.assignedSlots[0].slotStatus;
        return null;
    }

    public day: HDate;
    public slotId: number;
    public interval: HHourInterval;
    public intervalDock: HHourInterval;
    public intervalLift: HHourInterval;
    public isSlotBlocked: boolean;
    public isFinished: boolean;
    public slotStatus: PhaseSlotLogTypes;
    public additionalInformation: string;
}