<div class="container" [ngClass]="{collapsed: isListCollapsed}">
    <!-- Filtres i accions -->
    <div class="filters">
        <shared-filter class="extrapadding" [filterstrip]="filterBookings" (change)="filterUpdate()"></shared-filter>
        <actions [actionstrip]="actions" (clickAction)="onClickAction($event)"></actions>
    </div>

    <!-- Llista de bookings i cercador -->
    <div class="list llista" [ngClass]="{
        'create-mode': selectedBooking && !selectedBooking.bookingId,
        'edit-mode': selectedBooking && selectedBooking.bookingId
    }">
        <div class="list-header">
            <div class="title">
                {{ "BOOKINGPAGE.TITLE" | translate }}
                <span class="count">{{ filterMetadata.count }} / {{ bookings.length }}</span>
            </div>
            <input type="text" class="searchbox" placeholder="{{ 'SEARCH' | translate }}" [(ngModel)]="searchText"
                (ngModelChange)="searchTextUpdate.next($event)" />
        </div>
        <div class="list-content">
            <shared-booking-card *ngFor=" let item of exactMatchBookings; let i = index"
                [ngClass]="{selected: selectedBooking && item.bookingId == selectedBooking.bookingId}"
                (click)="editMode(item)" [booking]="item">
            </shared-booking-card>

            <shared-booking-card
                *ngFor=" let item of bookings | filterActiveRoutes : searchText: filterMetadata  | excludeBookings:exactMatchBookings; let i = index"
                [ngClass]="{ 
                    selected: selectedBooking && item.bookingId == selectedBooking.bookingId 
                }" (click)="editMode(item)" [booking]="item">
            </shared-booking-card>
        </div>

        <div class="collapsable" (click)="isListCollapsed = !isListCollapsed">
            <i class="fa" [ngClass]="{'fa-chevron-right': isListCollapsed, 'fa-chevron-left': !isListCollapsed}"></i>
        </div>
    </div>

    <!-- Contingut del booking -->
    <div class="booking" [ngClass]="{
            'create-mode': selectedBooking && !selectedBooking.bookingId,
            'edit-mode': selectedBooking && selectedBooking.bookingId
        }" *ngIf="selectedBooking">

        <!-- Cancel·lar selecció només per a mobile -->
        <div class="close"><i class="fa fa-times" (click)="selectedBooking = null"></i></div>

        <!-- Capçalera del Booking sel·leccionat -->
        <shared-booking-card *ngIf="selectedBooking && selectedBooking.bookingId" class="header no-clickable"
            [booking]="selectedBooking">
        </shared-booking-card>

        <!-- Tabs -->
        <div class="tabs" *ngIf="selectedBooking && selectedBooking.bookingId">
            <options [optionstrip]="options" (clickOption)="onClickOption($event)"></options>
            <shared-booking-state [booking]="selectedBooking" [readonly]="readonly"></shared-booking-state>
        </div>

        <!-- Gestió -->
        <div class="manage" *ngIf="selectedOptionId == 'MANAGE'">
            <div class="list llista">
                <div class="title">
                    {{ "DESKTOP.ROUTE.PAGE.COLUMN.ROUTES.TITLE" | translate }}
                    <span class="count">{{ filterMetadataBookingRoutes.count }} / {{ bookingRoutes.length }}</span>
                </div>
                <input type="text" class="searchbox" placeholder="{{ 'SEARCH' | translate }}"
                    [(ngModel)]="searchTextBookingRoutes" />
                <div class="list-content">
                    <shared-route-item
                        *ngFor="let item of bookingRoutes | filterActiveRoutes:searchTextBookingRoutes:filterMetadataBookingRoutes | orderbyroutecarddto:true; let i=index"
                        (click)="selectedBookingRoute = item" (dblclick)="onDoubleClickBookingRoute(item)"
                        [selected]="selectedBookingRoute && selectedBookingRoute.routeId == item.routeId"
                        [route]="item">
                    </shared-route-item>
                </div>
            </div>
            <div class="content">
                <shared-booking-route *ngIf="selectedBookingRoute" [route]="selectedBookingRoute"
                    (onRouteChanged)="onBookingRouteChanged($event, true)"
                    (onRouteRefresh)="onBookingRouteChanged($event, false)"></shared-booking-route>
            </div>
        </div>


        <!-- Rutes -->
        <div class="routes" *ngIf="selectedOptionId == 'ROUTES'">
            <!-- Header -->
            <div class="divSeccio header">
                <input type="text" class="searchbox" placeholder="{{ 'SEARCH' | translate }}"
                    [(ngModel)]="searchTextBookingRoutes" />

                <actions *ngIf="!showingSelectedRoutes" [actionstrip]="bookingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>
                <actions *ngIf="showingSelectedRoutes" [actionstrip]="confirmPendingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>
            </div>

            <div class="divSeccio header" *ngIf="showingSelectedRoutes">
                <span class="explanation">{{ 'BOOKINGPAGE.CONFIRMRELEASE.HELP' | translate: { routesCount:
                    selectedRoutes.length } }}</span>
            </div>
            <!-- Pendientes de gestionar -->
            <div class="divSeccio"
                *ngIf="bookingRoutesPendingManage && bookingRoutesPendingManage.length > 0 && !showingSelectedRoutes">
                <span class="title">{{ 'BOOKINGPAGE.ROUTESPENDINGMANAGE.CAPTION' | translate }}
                    ({{bookingRoutesPendingManage.length}})</span>
                <div class="routes">
                    <shared-route-item
                        *ngFor="let item of bookingRoutesPendingManage | filterActiveRoutes:searchTextBookingRoutes:filterMetadataBookingRoutesPendingManage | orderbyroutecarddto:true; let i=index"
                        [selected]="selectedRoutes.includes(item.routeId)" (click)="onSelectBookingRoute(item)"
                        (dblclick)="onDoubleClickBookingRoute(item)" [route]="item">
                    </shared-route-item>
                </div>
            </div>
            <!-- Gestionadas -->
            <div class="divSeccio"
                *ngIf="bookingRoutesManaged && bookingRoutesManaged.length > 0 && !showingSelectedRoutes">
                <span class="title">{{ 'BOOKINGPAGE.ROUTESMANAGED.CAPTION' | translate }}
                    ({{bookingRoutesManaged.length}})</span>
                <div class="routes">
                    <shared-route-item
                        *ngFor="let item of bookingRoutesManaged | filterActiveRoutes:searchTextBookingRoutes:filterMetadataBookingRoutesManaged | orderbyroutecarddto:true; let i=index"
                        [selected]="selectedRoutes.includes(item.routeId)" (click)="onSelectBookingRoute(item)"
                        (dblclick)="onDoubleClickBookingRoute(item)" [route]="item">
                    </shared-route-item>
                </div>
            </div>
            <!-- Cargadas -->
            <div class="divSeccio" *ngIf="bookingRoutesDone && bookingRoutesDone.length > 0 && !showingSelectedRoutes">
                <span class="title">{{ 'BOOKINGPAGE.ROUTESLOADED.CAPTION' | translate }}
                    ({{bookingRoutesDone.length}})</span>
                <div class="routes">
                    <shared-route-item
                        *ngFor="let item of bookingRoutesDone | filterActiveRoutes:searchTextBookingRoutes:filterMetadataBookingRoutesDone | orderbyroutecarddto:true; let i=index"
                        [selected]="selectedRoutes.includes(item.routeId)" (click)="onSelectBookingRoute(item)"
                        (dblclick)="onDoubleClickBookingRoute(item)" [route]="item">
                    </shared-route-item>
                </div>
            </div>

            <div class="divSeccio" *ngIf="showingSelectedRoutes">
                <shared-route-item
                    *ngFor="let item of getSelectedBookingRoutes() | filterActiveRoutes:searchTextBookingRoutes:filterMetadataBookingRoutes | orderbyroutecarddto:true; let i=index"
                    [route]="item">
                </shared-route-item>

            </div>
        </div>

        <!-- Afegir rutes -->
        <div class="add-routes" *ngIf="selectedOptionId == 'ADDROUTES'">
            <div class="divSeccio header">
                <input type="text" class="searchbox" placeholder="{{ 'SEARCH' | translate }}"
                    [(ngModel)]="searchTextPendingRoutes"
                    (ngModelChange)="searchTextPendingRoutesUpdate.next($event)" />

                <shared-address-ambit-selector-component [value]="addressAmbit"
                    (changeValues)="onChangeAddressAmbitSelector($event)">
                </shared-address-ambit-selector-component>

                <actions *ngIf="!showingSelectedPendingRoutes" [actionstrip]="bookingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>
                <actions *ngIf="showingSelectedPendingRoutes" [actionstrip]="confirmPendingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>

            </div>

            <div class="divSeccio header">
                <span *ngIf="showingSelectedPendingRoutes" class="explanation">{{ 'BOOKINGPAGE.CONFIRMPENDING.HELP' |
                    translate: { routesCount: selectedPendingRoutes.length } }}</span>
            </div>

            <div class="divSeccio" *ngIf="!showingSelectedPendingRoutes">
                <shared-route-item
                    *ngFor="let item of pendingRoutes | filterActiveRoutes:searchTextPendingRoutes:filterMetadataPendingRoutes | orderbyroutecarddto:true | slice:0:sliceCount; let i=index"
                    [route]="item" (click)="onSelectPendingRoute(item)"
                    [selected]="selectedPendingRoutes.includes(item.routeId)">
                </shared-route-item>
                <button class="slice-button" (click)="onSliceMorePendingRoutes()"
                    *ngIf="sliceCount!=pendingRoutesCount && sliceCount<filterMetadataPendingRoutes.count">
                    {{'GENERAL.SLICE.BUTTON' | translate:{ sliceCount: sliceCount, itemsCount:pendingRoutesCount} }}
                </button>
            </div>

            <div class="divSeccio" *ngIf="showingSelectedPendingRoutes">
                <shared-route-item
                    *ngFor="let item of getSelectedPendingRoutes() | filterActiveRoutes:searchTextPendingRoutes:filterMetadataPendingRoutes | orderbyroutecarddto:true; let i=index"
                    [route]="item">
                </shared-route-item>
            </div>
        </div>

        <!-- Editor / Creador -->
        <shared-booking-form *ngIf="selectedOptionId == 'EDIT'" (onSave)="onSave($event)"
            [selectedBooking]="selectedBooking"></shared-booking-form>

        <!-- Conflictes -->
        <div class="routes conflicts" *ngIf="selectedOptionId == 'CONFLICTS'">
            <!-- Header -->
            <div class="divSeccio header">
                <input type="text" class="searchbox" placeholder="{{ 'SEARCH' | translate }}"
                    [(ngModel)]="searchTextConflictiveRoutes" />

                <actions *ngIf="!showingSelectedConflictiveRoutes" [actionstrip]="bookingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>
                <actions *ngIf="showingSelectedConflictiveRoutes" [actionstrip]="confirmPendingRoutesActions"
                    (clickAction)="onClickBookingRouteAction($event)">
                </actions>


            </div>

            <div class="divSeccio header">
                <span class="explanation" *ngIf="!showingSelectedConflictiveRoutes">{{
                    'BOOKINGPAGE.CONFLICTIVEROUTES.HELP' | translate: {'routesCount': conflictiveRoutes.length}
                    }}</span>
                <span class="explanation" *ngIf="showingSelectedConflictiveRoutes">{{ 'BOOKINGPAGE.CONFIRMRELEASE.HELP'
                    | translate: { routesCount: selectedConflictiveRoutes.length } }}</span>
            </div>

            <!-- Llista rutes conflictives -->
            <div class="divSeccio"
                *ngIf="conflictiveRoutes && conflictiveRoutes.length > 0 && !showingSelectedConflictiveRoutes">
                <div class="routes">
                    <shared-route-item
                        *ngFor="let item of conflictiveRoutes | filterActiveRoutes:searchTextConflictiveRoutes:filterMetadataConflictiveRoutes | orderbyroutecarddto:true; let i=index"
                        [selected]="selectedConflictiveRoutes.includes(item.routeId)"
                        (click)="onSelectConflictiveRoute(item)" (dblclick)="onDoubleClickBookingRoute(item)"
                        [route]="item">
                    </shared-route-item>
                </div>
            </div>

            <div class="divSeccio" *ngIf="showingSelectedConflictiveRoutes">
                <shared-route-item
                    *ngFor="let item of getSelectedConflictiveRoutes() | filterActiveRoutes:searchTextConflictiveRoutes:filterMetadataConflictiveRoutes | orderbyroutecarddto:true; let i=index"
                    [route]="item">
                </shared-route-item>
            </div>
        </div>
    </div>
</div>