<!--<p>slot-picker works!</p>-->
<div *ngIf="phase != null && phase.requiresSlot" class="block selector-slots-container" [ngClass]="generalClass">
    <div *ngIf="hideTitle == false" class="titol">
        {{'SHARED.SLOT.PICKER.SLOTS.CAPTION'| translate}}
    </div>
    <div class="block firstlastmoment">
        <table class="limitada">
            <tr>
                <td class="prompt">{{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONFIRSTMOMENT.CAPTION' | translate}}
                </td>
                <td class="value">{{phase.fastConditionFirstMoment | datehournotz}}</td>
            </tr>
            <tr>
                <td class="prompt">{{'SHARED.PHASE.ITEM.MAINTENANCE.FASTCONDITIONLASTMOMENT.CAPTION' | translate}}
                </td>
                <td class="value">{{phase.fastConditionLastMoment | datehournotz}}</td>
            </tr>
            <tr *ngIf="booking && showDespositStartBooking" class="booking">
                <td class="prompt">
                    {{'BOOKINGPAGE.DEPOSIT.LABEL' | translate}}
                </td>
                <td class="value">{{booking.depositStart | datehournotz}}</td>
            </tr>
            <tr *ngIf="booking && showDespositEndBooking" class="booking">
                <td class="prompt">
                    {{'BOOKINGPAGE.DEPOSITEND.CAPTION' | translate}}
                </td>
                <td class="value">{{booking.depositEnd | datehournotz}}</td>
            </tr>
        </table>
    </div>
    <div class="help">{{'SHARED.SLOT.PICKER.FIRSTLASTMOMENT.HELP' | translate}}</div>
    <shared-typology-time-box *ngIf="phase.showDurada" [value]="phase"></shared-typology-time-box>
    <div class="slot-reservat"
        *ngIf="phase!=null && phase.intervalInfo!=null && phase.intervalInfo.addressTimetableIntervalId!=0">
        <div class="buttonInterval">
            <div class="dia-hora">
                <div class="dia">
                    {{phase.intervalInfo.onDate | date}}
                </div>
                <div class="hora">
                    {{phase.intervalInfo.interval | hourinterval}}
                </div>
            </div>
            <div class="additionInformation">
                {{phase.intervalInfo.additionalInformation}}
            </div>
            <div class="observacions" *ngIf="showObservacions">
                {{ 'DESKTOP.SLOT.PICKER.COMPONENT.OBSERVACIONS' | translate:{realDate:realDate | date, realInterval:
                realInterval | hourinterval} }}
            </div>
        </div>
    </div>

    <div class="block onlyisBlockedBookSlotSystem">
        <div class="" [innerHTML]="'SHARED.SLOT.PICKER.SLOTS.ISBLOCKEDBOOKSLOTSYSTEM' | translate"></div>
    </div>
    <div class="block bloqueig">
        <div class="" [innerHTML]="'SHARED.SLOT.PICKER.SLOTS.ISSLOTBLOCKED' | translate"></div>
        <div class="block-explanation-reason" [innerHtml]="phase.isSlotBlockedExplanation"></div>
    </div>
    <div class="slot-actiu">
        <button class="link" [ngClass]="{'small-font' : useIonic}" (click)="toggleCanviSlot()">
            {{'DESKTOP.SLOT.PICKER.COMPONENT.BUTTON.CANVISLOT'| translate}}</button>
    </div>
    <div>
        <div class="botonera-buttonInterval">
            <div class="button-reserveoutofhours" [ngClass]="{'hidden': !canReserveOutOfHours}">
                <button class="link" [ngClass]="{'small-font' : useIonic}" (click)="toggleReservarForaHores()">
                    <span *ngIf="showReservarForaHores">{{
                        "DESKTOP.SLOT.PICKER.COMPONENT.BUTTON.REESERVARFORAHORES.HIDDEN" | translate}}</span>
                    <span *ngIf="!showReservarForaHores">{{
                        "DESKTOP.SLOT.PICKER.COMPONENT.BUTTON.REESERVARFORAHORES.SHOW" | translate}}</span>
                </button>
            </div>
            <button *ngIf="!showReservarForaHores" class="icon right" [ngClass]="{'small-font' : useIonic}"
                (click)="reload()">
                <i class="fa fa-refresh" aria-hidden="true"></i></button>

            <button [ngClass]="{'hidden': !canSlotRelease}" class="buttonInterval item botoCancelar"
                (click)="clickAlliberarSlot()" *ngIf="!showReservarForaHores">

                <div class="hora">
                    {{'SHARED.SLOT.PICKER.COMPONENT.BUTTON.ALLIBERARSLOT.CAPTION'| translate}}
                </div>
                <div class="estat">
                    {{'SHARED.SLOT.PICKER.COMPONENT.BUTTON.ALLIBERARSLOT.HELP'| translate}}
                </div>
            </button>

            <div *ngIf="!showReservarForaHores" class="listintervals">
                <ng-container *ngFor="let item of addressintervals | sortBy:[]; let i = index;">

                    <div class="date-break" *ngIf="trencaData(i, item)">
                        <div class="line"></div>
                        <div class="date-container">
                            <div class="date">{{item.onDate | date}}</div>
                        </div>
                    </div>
                    <div class="buttonInterval item"
                        [ngClass]="{'swapVision': item.canSwap||item.canObtain, 'assignable':item.assignable,'no-assignable':!item.assignable,'notrecommendable':item.notRecommendable,'inactive':item.inActive, 'selected-item': isSelected(item), 'impossible-interval': item.impossibleInterval, 'swap-interval': item.canSwap, 'obtain-interval': item.canObtain }"
                        (click)="onIntervalSelected(item)"
                        matTooltip="{{ 'DESKTOP.SLOT.PICKER.COMPONENT.SLOT.BUTTON.TOOLTIP' | translate: { slotAvailableByMe:item.slotAvailableByMe, slotOccupiedByMe:item.slotOccupiedByMe, sum: item.slotAvailableByMe+item.slotOccupiedByMe,slotAvailable:item.slotAvailable,  slotCount:item.slotCount } }}">
                        <div class="routekey">
                            {{ item.routeKeySwap }}
                        </div>
                        <div class="dia">
                            {{item.onDate | date}}
                        </div>
                        <div class="hora">
                            {{item.interval | hourinterval}}
                        </div>
                        <div class="estat">
                            {{ 'DESKTOP.SLOT.PICKER.COMPONENT.SLOT.BUTTON.ESTAT' |
                            translate:{slotAvailableByMe:item.slotAvailableByMe, slotOccupiedByMe:item.slotOccupiedByMe,
                            sum:
                            item.slotAvailableByMe+item.slotOccupiedByMe,slotAvailable:item.slotAvailable,
                            slotCount:item.slotCount}
                            }}
                        </div>
                        <div class="observacions" *ngIf="getShowObservacionsFunc(item)">
                            {{ 'DESKTOP.SLOT.PICKER.COMPONENT.ITEMS.OBSERVACIONS' |
                            translate:{realDate:getRealDateFunc(item) |
                            date,
                            realInterval: getRealIntervalFunc(item) | hourinterval} }}
                        </div>
                        <div class="intervalDockForklift" *ngIf="item.showIntervalDockForklift">
                            {{ 'DESKTOP.SLOT.PICKER.COMPONENT.ITEMS.INTERVALDOCKFORKLIFT' |
                            translate:{intervalDock: item.intervalDock | hourinterval,
                            intervalWheelbarrowDriver: item.intervalWheelbarrowDriver | hourinterval} }}
                        </div>
                        <div class="notRecommendableReason" *ngIf="item.notRecommendable"
                            [innerHTML]="item.notRecommendableReason">
                        </div>
                        <div class="noAvailableReason" *ngIf="item.slotAvailableByMe==0 || !item.assignable"
                            [innerHTML]="item.noAvailableReason">
                        </div>
                    </div>
                </ng-container>
                <div class="loading-label onlyloading" [ngClass]="{'loading': loading}">
                    {{ 'SHARED.LOADING.CAPTION' | translate}}
                </div>
                <div class="loading-label onlynooptions" [ngClass]="{'nooptions': nooptions}">
                    {{ 'SHARED.NOOPTIONS.CAPTION' | translate}}
                </div>
            </div>

            <div *ngIf="showReservarForaHores" class="reserveoutofhours">
                <shared-reserveoutofhours-box [phaseId]="phase.phaseId"
                    (finishReserve)="onReservarForaHoresFinish($event)"></shared-reserveoutofhours-box>
            </div>
        </div>
    </div>
</div>

<div *ngIf="phase != null && !phase.requiresSlot" class="block selector-slots-container">
    <div class="loading-label">
        {{ 'SHARED.NOOPTIONS.CAPTION' | translate}}
    </div>
</div>