import { OptionsStripDto } from './OptionsStripDto';
import { OptionsMaintenance } from '@shared/src/enums/OptionsMaintenance';

export class OptionDto {
    public optionId: string;//Ha de ser string ja que ens arriben més opcions de back i poden ser de qualsevol entitat //Suposadament per diferenciarlo dels altres. És Unic
    public optionCaption: string; //el recurs que utilitzarem com anom
    public optionHelp: string; //explicació de la opció, es mostrar com a tooltip molt segurament
    public optionIcon: string; //nom de la icona. Els noms els trobaràs a https://material.io/icons/
    public optionOrder: number; //Ordre de la opció0. A més petit es mostra més a l'esquerra a l'estrip o a la part superior
    public optionShowAs: number; //+1 es mostra com a iciona, -1, com a text, 0 com les dues.
    public optionShowIn: number; //+1 es mostra a la barra, -1 al menu, 0 a les dues
    public optionSelected: boolean //indica si és la opció sel·leccionada
    public classString: string; //Permet indicar quines classes addicionals s'han d'aplicar al option en concret. Permet canviar background-color, etc.
    public classification: string; //permet fer filtres amb les opcions. no-toolbar fa que la opció no es vegi en les toolbar. bottom fa que es mostri a la zona inferior dels panes. Recorda que list o menu no afecta al pane ja que s'aplica quan el pane es torna tant petit i es converteix en toolbar que agafa la forma de botó o menú. Per afectr a dalt o baix dle pane has de posar bottom

    public optionStrip: OptionsStripDto;

    public static build(optionid: string, optioncaption: string, optionhelp: string, optionicon: string, optionorder: number, optionShowAs: number, optionShowIn: number, optionselelected: boolean, alternativecolor: boolean): OptionDto {
        let result = new OptionDto();
        result.optionId = optionid;
        result.optionCaption = optioncaption;
        result.optionHelp = optionhelp;
        result.optionIcon = optionicon;
        result.optionOrder = optionorder;
        result.optionShowAs = optionShowAs;
        result.optionShowIn = optionShowIn;
        result.optionSelected = optionselelected;
        result.classString = "option-" + optionid.toLowerCase();
        return result;
    }
    static buildFromOptionDto(origin: OptionDto): OptionDto {
        let result = new OptionDto();
        result.optionCaption = origin.optionCaption;
        result.optionHelp = origin.optionHelp;
        result.optionIcon = origin.optionIcon;
        result.optionId = origin.optionId;
        result.optionOrder = origin.optionOrder;
        result.optionShowAs = origin.optionShowAs;
        result.optionShowIn = origin.optionShowIn;
        result.classString = origin.classString;
        result.optionSelected = origin.optionSelected;
        result.classification = origin.classification;
        return result;
    }

    //+1 es mostra com a iciona, -1, com a text, 0 com les dues.
    public static getOptionShowAsIcon(value: OptionDto): boolean {
        return value.optionShowAs >= 0;
    }
    public static getOptionShowAsText(value: OptionDto): boolean {
        return value.optionShowAs <= 0;
    }

    //+1 es mostra a la barra, -1 al menu, 0 a les dues
    public static getOptionShowInBar(value: OptionDto): boolean {
        return value.optionShowIn >= 0;
    }
    public static getOptionShowInMenu(value: OptionDto): boolean {
        return value.optionShowIn <= 0;
    }


}