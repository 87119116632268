<div *ngIf="parentObject" class="parentobjectdocuments-item" [ngClass]="{'ionic' : useIonic}">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="item-title">
                    <div class="top-left">
                        {{ resourceTitle | translate }} <span class="numdocuments">{{ 'DOCUMENTREVIEW.NUMDOCUMENTS' |
                            translate:{value: numDocuments} }}</span>
                    </div>
                    <div class="top-center">

                    </div>
                    <div class="top-right">

                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <shared-documentaryreview-documents-component [doLoadDocuments]="doLoadDocuments"
                [parentObject]="parentObject" [documents]="documents" (dataRefresh)="onDataRefresh($event)">
            </shared-documentaryreview-documents-component>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</div>