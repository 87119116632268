<div *ngIf="value" class="ecmr-item" [ngClass]="{'ionic' : useIonic}">
    <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="item-title">
                    <div class="top-left">
                        {{ resourceTitle | translate}}
                        <span class="block" *ngIf="value.originAddressScreen">
                            <span class="key"><i class="fa fa-sign-out"
                                    aria-hidden="true"></i>{{value.originAddressScreen}}</span>
                            <span class="address">{{value.originFormattedAddress}}</span>
                        </span>
                        <span class="block" *ngIf="value.destinationAddressScreen">
                            <span class="key"><i class="fa fa-sign-in"
                                    aria-hidden="true"></i>{{value.destinationAddressScreen}}</span>
                            <span class="address">{{value.destinationFormattedAddress}}</span>
                        </span>
                    </div>
                    <div class="top-center">

                    </div>
                    <div class="top-right">

                    </div>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-expansion-panel-body>
            <shared-route-part-cmr-document-component [value]="value"
                (dataRefresh)="onDataRefresh($event)"></shared-route-part-cmr-document-component>
        </mat-expansion-panel-body>
    </mat-expansion-panel>
</div>