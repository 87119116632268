<ng-container *ngIf="value">
    <options class="nomenu" [optionstrip]="options" (clickOption)="onClickOption($event)">
    </options>
    <shared-route-part-cmr-digitaldocument-component class="nosignatures" [routePartCMRId]="getRoutePartCMRID()"
        [documentDetailId]="getDocumentDetailId()" [readonly]="true" [avoidOptions]="true"
        *ngIf="getRoutePartCMRID()>0"></shared-route-part-cmr-digitaldocument-component>
    <div class="sign-line">{{'GLOBAL.SIGN'|translate}}</div>
    <shared-sign-cmr-maintenance [signUUID]="uuid" [type]="agent"
        (signedChanged)="onSignedChanged()"></shared-sign-cmr-maintenance>
    <div class="dummy">
        dummy
    </div>
</ng-container>