import { Component, Inject, Input } from '@angular/core';
import { HomeController } from '@shared/src/controllers/home/home.controller';
import { OpenRouteController } from '@shared/src/controllers/open/open.route.controller';
import { HLong } from '@shared/src/datatypes/HLong';
import { OptionDto } from '@shared/src/dtos/maintenance/option/OptionDto';
import { OptionsStripDto } from '@shared/src/dtos/maintenance/option/OptionsStripDto';
import { RouteGroupChildItemDto } from '@shared/src/dtos/route/RouteGroupChildItemDto';
import { RouteGroupChildListDto } from '@shared/src/dtos/route/RouteGroupChildListDto';
import { AlertService, HString, RouteController } from '@shared/src/public-api';

@Component({
  selector: 'shared-route-group-child-list-component',
  templateUrl: './shared.route.group.child.list.component.html',
  styleUrls: ['./shared.route.group.child.list.component.scss'],
})
export class SharedRouteGroupChildListComponent {

  constructor(private routeController: RouteController, public openRouteController: OpenRouteController,
    @Inject('HomeController') protected homeController: HomeController,
    @Inject('AlertService') protected alertService: AlertService) {

  };

  @Input() public showtitle: boolean;

  public __phaseId: number;
  @Input()
  set phaseId(value: number) {
    if (this.__phaseId === value)
      return;
    this.__phaseId = value;
    this.loadDataByPhaseId();
  }
  get phaseId(): number {
    return this.__phaseId;
  }

  public __routeId: number;
  @Input()
  set routeId(value: number) {
    if (this.__routeId === value)
      return;
    this.__routeId = value;
    this.loadDataByRouteId();
  }
  get routeId(): number {
    return this.__routeId;
  }

  public __uuidexwork: string;
  @Input()
  set uuidexwork(value: string) {
    if (this.__uuidexwork === value)
      return;
    this.__uuidexwork = value;
    this.loadDataByUUIDExwork();
  }
  get uuidexwork(): string {
    return this.__uuidexwork;
  }


  public value: RouteGroupChildListDto;

  public loadDataByPhaseId() {
    if (this.__phaseId == null || this.__phaseId == 0) {
      this.value = null;
      return;
    }
    this.routeController.getRouteGroupChildListByPhaseId(this.phaseId).subscribe(data => {
      this.loadData(data);
    });

  }
  public loadDataByRouteId() {
    if (this.__routeId == null || this.__routeId == 0) {
      this.value = null;
      return;
    }
    this.routeController.getRouteGroupChildListByRouteId(this.routeId).subscribe(data => {
      this.loadData(data);
    });

  }
  public loadDataByUUIDExwork() {
    if (HString.isNullOrNullString(this.__uuidexwork)) {
      this.value = null;
      return;
    }
    this.openRouteController.getRouteGroupChildListByUuidExwork(this.uuidexwork).subscribe(data => {
      this.loadData(data);
    });

  }

  public actualOptionId: string = "SHIPMENTS";
  public options: OptionsStripDto;
  public loadData(data: RouteGroupChildListDto) {
    this.value = data;
    if (this.value != null) {
      if (this.value.options != null)
        this.options = OptionsStripDto.buildFromOptionsStripDto(this.value.options);
      this.actualOptionId = this.value.optionDefault;
    }
  }

  onClickOption(option: OptionDto) {
    this.actualOptionId = option.optionId;
  }

  ngOnInit() {

  }
  copy(value: string) {
    if (value != null && value != "")
      this.alertService.copyToClipboard(value).subscribe(e => { });
  }
  pretty(value: any): string {
    return JSON.stringify(value);
  }

  public onARClick(route: RouteGroupChildItemDto) {
    if (route && !HLong.isNullOrNullLong(route.routeId))
      this.homeController.goToActiveRoutes(route.routeId);
  }
  public onASClick(route: RouteGroupChildItemDto) {
    if (route && !HLong.isNullOrNullLong(route.routeId))
      this.homeController.goToActiveSlots(route.routeId);

  }
}
